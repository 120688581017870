.gameContainer {
  margin-top: 50px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.input {
  height: 50px;
  font-size: 1.2em;
}

.listItem {
  font-size: 1.2em;
  text-align: left;
}

@media (max-width: 768px) {
  .gameContainer {
    margin-top: 20px;
  }

  .input {
    font-size: 1em;
  }

  .listItem {
    font-size: 1em;
  }
}