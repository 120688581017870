.analysis-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
}

.icon-down {
  font-size: 24px;
}

.open {
  transform: rotate(180deg);
}

.prod-scale {
  height: 44px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.prod-scale span {
  font-weight: bold;
}

.prod-scale .session {
  background: orange;
  color: white;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
