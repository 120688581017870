.test-Letter {
  font-size: 15px;
  line-height: 19px;
  font-size: 20px;
  line-height: 43px;
}

.test-Letter-not {
  color: #0d064e;
}

.test-Letter-incorrect {
  color: #bf360c;
}

.test-Letter-correct {
  color: #3a8505;
}
