code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
  margin: 0px;
  font-family: soraregular !important;
  top: 0px !important;
  overflow-x: hidden;
}
html {
  scroll-behavior: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.module-section h2 {
  margin-top: 50px !important;
  font-size: 32px !important;
}
.module-edit {
  width: 100%;
  padding: 10px 28px;
  margin-top: 45px;
}

.language-row {
  margin-top: 100px;
}
.cardlang img {
  height: 100px;
  width: 100px;
  margin-top: 20px;
}
.cardlang {
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  text-align: center;
  align-items: center;
}
/* .txt-container div:nth-child(1) {
  position: static !important;
  background-color: transparent;
} */
.language-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
.common-btn-language {
  padding: 10px 41px;
  background: var(--primary-gradient);
  border: none;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 9px 3px rgb(97 214 221 / 28%);
}

#google_translate_element {
  width: 300px;
  float: right;
  text-align: right;

  visibility: hidden;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
#goog-gt-tt {
  display: none !important;
  top: 0px !important;
}
.goog-tooltip skiptranslate {
  display: none !important;
  top: 0px !important;
}
.activity-root {
  display: hide !important;
}
.status-message {
  display: hide !important;
}
.started-activity-container {
  display: hide !important;
}

.goog-tooltip {
  display: none !important;
}
.goog-tooltip:hover {
  display: none !important;
}
.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.goog-te-combo {
  width: 200px;
  height: 50px;
  position: absolute;
  right: 10px;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}
.txt-container > div {
  width: 50% !important;
}
.txt-container div:nth-child(1) {
  height: inherit !important;
  /* width: 100% !important; */
  /* position: static !important; */
  font-size: 22px;
  font-weight: 600;
  padding-left: 0;
  padding-top: 0;
  background-color: transparent;
  left: inherit !important;
  right: 0;
  text-align: center;
  padding-right: 0;
  min-height: inherit;
}

/* .txt-container div:nth-child(1) */
.dropdown-image .active {
  background-color: #e4f4f2 !important;
  color: var(--primary-color) !important;
}
.css-13cymwt-control {
  border: none !important;
  background-color: transparent !important;
  width: 100% !important;

  cursor: pointer !important;
}
.css-hlgwow {
  padding: 0 !important;
}
.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}
.css-t3ipsp-control {
  border-radius: 0 !important;
  border: none !important;
}
.css-t3ipsp-control:hover {
  border-color: transparent !important;
  box-shadow: unset;
  background-color: transparent !important;
}
.pro-menu-item > .pro-item-content {
  background-color: #fff;
  outline: navajowhite;
  box-shadow: 0px 18.9954px 25.6438px rgb(0 0 0 / 5%);
}

.dots {
  background-image: radial-gradient(87.17% 87.17% at 5.87% 9.69%, #ff9595 0%, #e83e3e 100%);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

:root {
  --primary-gradient: radial-gradient(98.07% 73.14% at 3.51% 19.05%, #97d5cd 8.51%, #54baaf 100%);
  --primary-color: #6bc3ba;
  --green-color: #3c726e;
  --green-light-color: #98e8df;
  --green-border-color: #00bdb2;
  --green-highlight-color: rgba(107, 195, 186, 0.22);
  --light-gray: #d2d2d2;
  --secondary-color: rgba(3, 2, 2, 0.612);
  --grey-color: #f5f5f5;
}

a:hover {
  text-decoration: none;
}

a {
  text-decoration: none !important;
}

button.active {
  background-color: var(--primary-gradient);
  color: #fff;
}

.landing-page-section {
  background-color: var(--green-light-color);
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
  left: -70px;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
}

.skin-blue .nav-sidebar .nav-link.active span {
  color: #000;
}

.h-100vh {
  height: calc(100vh - 80px);
}

.form-btn input {
  padding: 13px 10px;
  margin-top: 30px;
}

.full-btn {
  width: 100% !important;
  padding: 14px 10px;
  margin-top: 34px;
}
.nav-pills .nav-link {
  cursor: pointer;
}
.stepstext h2 {
  text-align: right;
  font-family: sorabold;
  color: var(--secondary-color);
}
.cross-vn {
  height: 40px;
  width: 40px !important;
  border-radius: 20px;
  line-height: 12px;
  text-align: center;
}
.moving-tab {
  display: none;
}
.active {
  color: var(--primary-color);
}
.wizard-container .card-header {
  background: #fff;
}
.wizard-container .nav-pills .nav-link.active,
.wizard-container .nav-pills .show > .nav-link {
  background: var(--primary-gradient);
}
.wizard-container .nav-pills .nav-link {
  background: var(--[--primary-color]);
}
.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  /* display: none; */
  cursor: pointer;
}

.btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  background-color: transparent !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  background-color: transparent !important;
}

.dropdown-menu {
  border: none;
  min-width: inherit;
  width: 100%;

  box-shadow: 0px 22px 25.6438px 2px rgb(0 0 0 / 5%);
  border-radius: 0px;
}

.dropdown-menu .dropdown-item {
  font-size: 12px;
}

.bg-light-green {
  background: #fbfcfc;
}

.dropdown-toggle:hover {
  background-color: transparent;
}

.back-btn {
  margin: 0px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.back-btn img {
  height: 12px;
  width: 12px;
}

label {
  font-size: 12px;
  color: #a0a0a0;
}

.form-group label {
  position: relative;
  cursor: pointer;
  color: #a0a0a0;
}

.emoji-result1 {
  display: flex;
  align-items: center;
  position: relative;
}

.emoji-result2 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.notify-badge {
  background: skyblue;
  text-align: center;
  border-radius: 50%;
  color: white;
  padding: 5px 13px;
  font-size: 24px;
  position: relative;
}

.emotion-badge {
  position: absolute;
  bottom: -2px;
  background-color: white;
  left: 28%;
  border-radius: 50%;
  padding: 9px 9px;
}

.emotion-badge2 {
  background-color: white;
  position: absolute;
  border-radius: 50%;
  padding: 9px 9px;
  bottom: 12px;
  left: 53%;
}

.emotion-badge3 {
  background-color: white;
  position: absolute;
  border-radius: 50%;
  padding: 9px 9px;
  bottom: 2px;
  right: -1%;
}

.emotion-badge4 {
  background-color: white;
  border-radius: 50%;
  padding: 9px 9px;
  position: absolute;
  bottom: -6px;
  left: 38%;
}

.emotion-badge5 {
  background-color: white;
  border-radius: 50%;
  padding: 9px 9px;
  position: absolute;
  bottom: -2px;
  right: 13%;
}

.Login-button p {
  text-align: center;
  padding-top: 25px;
}

/* .emoji-result1 img {
  width: 85%;
}
.emoji-result2 img {
  width: 80%;
} */
/* .form-group label:before {
  content: "";
  --webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0079bf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4px;
} */

.form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.two-partition {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.two-partition {
  margin: 20px 0px;
}

.two-partition p {
  margin: 0px;
}

.two-partition button {
  padding: 8px 8px;
}

.form-control {
  padding-left: 0px !important;
}

.form-floating {
  margin-bottom: 12px;
}

.form-floating label {
  padding-left: 0px;
  font-size: 16px;
}

.FormModal .modal-content {
  border-radius: 16px;
}
.cross-btn {
  position: absolute;
  right: 15px;
  cursor: pointer;
  top: 15px;
}

.FormModal .modal-body {
  padding-bottom: 40px;
}

/*checkbox styling*/
@font-face {
  font-family: sorabold;
  src: url('../fonts/sorabold.ttf');
}

.bg-fa {
  background-color: #fbfcfc !important;
}

@font-face {
  font-family: soralight;
  src: url('../fonts/soralight.ttf');
}

@font-face {
  font-family: soramedium;
  src: url('../fonts/soramedium.ttf');
}

@font-face {
  font-family: soraregular;
  src: url('../fonts/soraregular.ttf');
}

.btn:focus,
.btn.focus {
  box-shadow: none;
}
.skiptranslate {
  display: none !important;
}
/* .form-control {
  transition: 1s all;
} */
/* button {
  width: inherit !important;
} */
.form-control:focus {
  box-shadow: none;
  background-color: transparent;
  border-bottom: 2px solid var(--primary-color);
}

.common-btn {
  background: var(--primary-gradient);
  border: none;
  color: #fff;
  border-radius: 8px;
  /* width: 100% !important; */
  cursor: pointer;
  box-shadow: 0px 4px 9px 3px rgba(97, 214, 221, 0.28);
}

.w-100 {
  width: 100%;
}

.p-10 {
  padding: 10px;
}

input[type='text'] {
  outline: none;
  /* border-bottom: 7px solid  #EBEBEB; */
  height: 40px;
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ebebeb;
  border-radius: 0px;
  background-color: transparent;
}

textarea.form-control {
  outline: none;
  /* border-bottom: 7px solid  #EBEBEB; */
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ebebeb;
  border-radius: 0px;
  background-color: transparent;
}

input[type='password'] {
  outline: none;
  border: none;
  height: 40px;
  width: 100%;
  padding: 10px;
  border-bottom: 2px solid #ebebeb;
  border-radius: 0px;
  background-color: transparent;
}

input[type='date'] {
  outline: none;
  /* border-bottom: 7px solid  #EBEBEB; */
  height: 40px;
  width: 100%;
  background-color: transparent;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ebebeb;
  border-radius: 0px;
}

input[type='email'] {
  outline: none;
  /* border-bottom: 7px solid  #EBEBEB; */
  height: 40px;
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #ebebeb;
  border-radius: 0px;
}

input[type='number'] {
  outline: none;
  /* border-bottom: 7px solid  #EBEBEB; */
  height: 40px;
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #ebebeb;
  border-radius: 0px;
}

span.word {
  margin-right: 6px;
}

select {
  outline: none;
  /* border-bottom: 7px solid  #EBEBEB; */
  height: 40px;
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ebebeb;
  border-radius: 0px;
}

.form-select {
  border: navajowhite;
  border-radius: 0px;
  background-color: transparent;
  outline: none;
  border-bottom: 2px solid #ebebeb;
  height: 40px;
  width: 100%;
  padding: 10px 0px;
  font-size: 14px;
  color: #495057;
}
.can {
  cursor: pointer;
}
.pencil-icon {
  position: absolute;
  height: 150px;
  width: 150px;
  bottom: 120px;
  opacity: 0.3;
  left: 0;
}
.currencyGame .mcq-item {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
}
.currencyGame .drag-blob {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: grab;
  /* background: red; */
  z-index: 99;
}
.currencyGame .mcq-item img {
  cursor: pointer;
}
.option-mcq-container li {
  list-style-type: none;
}
.view-option img {
  border: 6px solid #c5c5c5;
  margin: 20px;
}
.currencyGame .mcq-item h4 {
  font-size: 40px;
}
h2 {
  font-family: 'soraregular';
  font-size: 22px !important;
}

.form-select:focus {
  box-shadow: none;
  border-bottom: 2px solid var(--primary-color);
}

main .blob {
  height: 100%;
  /* width: 100%; */
  position: absolute;
  top: 0px;
  z-index: -1;
  left: 0px;
  display: none;
}

.bg-active {
  background-color: var(--primary-color) !important;
  color: #fff;
}

/***************************header styling***************************/
/* header {
  height: 70px;
} */

header .navbar {
  padding: 24px 62px;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05); */
}

header .navbar .nav-item {
  margin-left: 20px;
}

header .navbar .nav-item a {
  font-size: 12px;
  /* text-transform: uppercase; */
  padding: 10px 16px;
}

header .navbar-brand figure {
  display: flex;
  align-items: center;
}

header .navbar-brand figure figcaption {
  font-weight: 600;
  color: #180f2c;
}

header .navbar-brand figure {
  margin: 0px;
  /* padding: 8px 3px; */
}

header .navbar-brand img {
  height: auto;
  width: 130px;
}

header .btn:hover {
  color: #fff;
}

header .navbar-nav {
  align-items: center;
}

header .btn-container .login-btn {
  color: var(--primary-color);
  padding: 12px 31px;
  font-size: 14px;
  display: none;
}

header .btn-container .common-btn:hover {
  text-decoration: none;
  color: #fff;
}

header .btn-container .common-btn {
  padding: 12px 31px;
  font-size: 14px;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
}

.dropdown-image {
  /* height:30px; */
  /* height: 30px; */

  margin: 0px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;

  /* flex-wrap: wrap; */
}

.module-container1 {
  position: relative;

  /* background: #c4c4c4; */
  border-radius: 6px;
}
.module-containr {
  width: 100%;
  overflow-x: scroll;
  padding: 12px 0px;
}
.dropdown-image img {
  height: 30px !important;
  width: 30px !important;
  object-fit: cover;
}
.sidebar {
  opacity: 1;
}
.animate .sidebar {
  transition: inherit;
}
.sidebar-logo img {
  height: auto;
  width: 126px;
}
.option-mcq-container ul {
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
}
.arrangeCoingame img {
  border: 4px solid #c4c4c4;
  border-radius: 50%;
  width: 180px;
  object-fit: cover;
}

.ham-option:hover figure {
  background-color: var(--primary-color);
}
.nav-sidebar .nav-link:hover figure {
  background-color: var(--primary-color);
}
.nav-sidebar .nav-link:hover figure svg {
  fill: #fff !important;
}
.numContainer {
  height: 100px;
  font-size: 50px !important;
  width: 100px;
  line-height: 94px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: 4px solid var(--primary-color);
}
.numContainer:hover {
  background-color: var(--primary-color);
  color: #fff;
}
.ham-option:hover figure svg {
  fill: #fff !important;
}
.childActive {
  border: 2px solid var(--primary-color) !important;
  background: var(--green-highlight-color);
}
.hame-menu .dropdown-image figcaption {
  color: var(--secondary-color);
  font-size: 13px;
}

.dropdown-image .downarrow {
  height: 14px !important;
  width: 14px !important;
  object-fit: contain;
}
.module-section {
  margin: auto;
}
.animate .content {
  width: calc(100% - 318px);
}
.sidebar {
  width: 248px;
}
/*********************************header styling end***************************/

/***********************************sidenav************************************/
.collapselink a {
  text-decoration: none;
}
.asked-color-box p {
  font-size: 14px !important;
  font-weight: 800;
}
.pro-sidebar > .pro-sidebar-inner {
  background-color: transparent;
}
.sidebar-body {
  overflow: inherit;
}
.sidebar-body li {
  position: relative;
  margin-bottom: 22px;
  padding: 0px 20px;
  border-right: 2px solid #fff;
}
.sidebar-body li:hover {
  border-color: var(--primary-color);
}
.animate .nav-sidebar {
  padding: 0;
}
.sidebar-body li a.active figure {
  background: var(--primary-gradient);
}

.ham-menu {
  flex: 0 0 12%;
  display: none;
}

.ham-menu i {
  font-size: 30px;
  color: var(--primary-color);
}

.sidebar-body li a figure {
  margin: 0px;
}

.sidebar-body li a figure {
  height: 30px;
  width: 30px;
  box-shadow: 0px 3.79909px 5.69863px -0.949771px rgba(0, 0, 0, 0.12),
    0px 1.89954px 3.79909px -0.949771px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  background: #fff;
}

.sidebar-body li a span {
  color: var(--secondary-color);
  font-family: 'soralight';
  font-size: 13px;
}

.nav-sidebar .nav-link svg {
  height: 100%;
  width: 100%;
  padding: 6px;
  margin: 0px;
}

.nav-sidebar .nav-link:hover svg {
  animation: swing ease-in-out 0.5s 3 alternate;
}

.sidebar-body li a {
  padding: 2px 22px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 11px;
  color: #b9b9bc;
}

.sidebar-body li.active:after {
  content: '';
  border: 6px solid var(--primary-gradient);
  position: absolute;
  left: 0px;
  top: 50%;
  height: 85%;
  transform: translate(-50%, -50%);
}

.sidebar-body li.active a {
  color: var(--primary-gradient);
}

.sidebar-body li a svg {
  height: 14px;
  width: 14px;
}

.skin-blue .nav-sidebar .nav-link span {
  font-weight: 900;
}

.skin-blue .nav-sidebar .nav-link.active figure {
  background: var(--primary-gradient);
}

.skin-blue .nav-sidebar .nav-item figure svg path {
  fill: #cfcfcf !important;
}

.skin-blue .nav-sidebar .nav-link.active figure svg path {
  fill: #fff !important;
}

.skin-blue .nav-sidebar .nav-link {
  gap: 30px;

  border-radius: 10px;
}

.skin-blue .nav-sidebar .nav-link span {
  color: #4141418a;
}

.skin-blue .nav-sidebar .nav-link.active {
  color: #0d6efd;
  background-color: #fff;
  outline: navajowhite;
  box-shadow: 0px 18.9954px 25.6438px rgb(0 0 0 / 5%);
}

/***********************************sidenav end************************************/

/*********************************************content-container-styling*******************************************/
.info-container {
  background-color: var(--green-light-color);
  padding-top: 50px;
  padding-bottom: 30px;
}

.info-container .container .content {
  text-align: center;
  padding: 0px 183px;
}

.info-container .container .content p {
  margin-bottom: 16px;
  font-size: 14px;
}

.info-container .container .content button {
  font-weight: 600;
  background-color: transparent;
  border-radius: 0px;
  border: 1px solid #000;
  border-bottom: 2px solid #000;
  padding: 8px 18px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.wrong .tickAbsolute2 {
  position: absolute;
  top: 0;
  left: 0;
  background: #ff00005e;
  height: 100%;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 61px;
  color: #fff;
  border: 14px solid #ff00005e;
}
.none {
  display: none !important;
}
.tickAbsolute {
  position: relative;
}
.wright .tickAbsolute1 {
  position: absolute;
  top: 0;
  left: 0;
  background: #00b89463;
  height: 100%;
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 61px;
  color: #fff;
  border: 14px solid #00b894;
}

.info-container .container .content button:hover {
  color: #000;
}

.info-container .container .content button::before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: #333;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.3s;
  z-index: -1;
}

.info-container .container .content button:hover {
  color: #fafafa;
}

.info-container .container .content button:hover::before {
  transform: none;
}

/*********************************************content-container-styling*******************************************/

/***********************select role styling***************************/
.select-role-section {
  margin-top: 20px;
  padding-bottom: 30px;
}

.select-role-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 30px;
}

.select-role-container .select-role-item {
  position: relative;
  outline: 0.5px solid rgba(32, 32, 32, 0.1);
  text-align: center;
  padding: 26px 50px;
  color: #aaaaaa;
  border-radius: 7px;
  cursor: pointer;
  flex: 0 0 calc(50% - 16px);
}

.select-role-container .select-role-item:hover {
  background-color: var(--primary-gradient);
  color: #fff;
}

.select-role-container .select-role-item:nth-child(2) {
  /* border-radius: 0px 10px 10px 0px; */
  margin-left: 14px;
}

.select-role-container .select-role-item figcaption {
  font-weight: 500;
  font-size: 20px;
  margin-top: 10px;
  color: var(--secondary-color);

  font-size: 13px;
}

.select-role-container .select-role-item svg {
  height: 95px;
  width: 80px;
  object-fit: contain;
  pointer-events: none;
}

.students-container {
  position: relative;
  margin-top: 20px;
}

.category-container .search-item {
  display: flex;
  align-items: center;
  gap: 9px;
}

.category-container .search-item h6 {
  margin: 0px;
  /* font-weight: 800; */
  font-family: 'sorabold';
  text-transform: capitalize;
  /* line-height: 54px; */
}

.category-container .search-item img {
  height: 60px;
  width: 60px;
  object-fit: contain;
  border-radius: 50%;
  background: #fff;
}

.select-role-container .select-role-item.active {
  background-color: var(--primary-gradient);
  color: #fff;
  /* flex: 0 0; */
  outline: 3px solid var(--primary-color);
}

.select-role-container .select-role-item.active:after {
  content: '';
  height: 40px;
  width: 40px;
  background: var(--primary-color);
  position: absolute;
  bottom: -40px;
  /* bottom: -30px; */
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-image: url(../images/tick.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.select-role-container .select-role-item.active figcaption {
  color: #000;
}
.select-role-container .select-role-item figcaption {
  font-family: 'sorabold';
}
.select-role-container .absolute-txt {
  border: 2px solid var(--primary-gradient);
  position: absolute;
  background: #fff;
  color: var(--primary-gradient);
  padding: 2px 24px;
  border-radius: 4px;
  top: 49%;
  writing-mode: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.role-container {
  text-align: center;
}

.role-container button {
  width: 28%;
  padding: 7px 10px;
  margin-top: 20px;
  outline: none;
  cursor: pointer;
}

.search-container {
  display: flex;
  align-items: center;
  border: 2px solid #d9d9d9;
  padding: 10px;
  margin-top: 30px;
  /* height: inherit; */
}

.search-container input {
  border: none;
  margin-left: 10px;
  width: 100%;
}

.search-container input {
  outline: none;
  height: inherit;
}

.search-container img {
  height: 20px;
  width: 20px;
}

.label-container button {
  padding: 15px 84px;
  cursor: pointer;
  outline: none;
  border-radius: 0px;
}

.search-item-container {
  margin-top: 48px;

  margin-top: 28px;
  padding: 0px 79px;
  padding-top: 0px;
  display: flex !important;
}

.search-item-container {
  margin-top: 28px;
  padding: 0px 79px;
  padding-top: 0px;
}

.search-item-container .search-item {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  cursor: pointer;
  background: #fff;
  padding: 10px 14px;
  box-shadow: 0px 5px 37px -15px rgb(19 78 82 / 13%);
  border-radius: 16px;
}

.search-item-container .search-item img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
}

.search-item-container .search-item figcaption {
  color: #202020cc;
  margin-left: 10px;
  font-size: 16px;
}

.search-item-container .search-item figcaption span {
  color: var(--secondary-color);

  font-size: 12px;
}

.search-item-container .search-item figcaption p {
  color: var(--secondary-color);

  margin: 0px;
  font-size: 12px;
}

.search-item-container .search-item figcaption h6 {
  font-size: 14px;
  text-align: left;
}

/**********************t*select role styling end***************************/

/***********parent-form styling**************/
.parent-container form {
  margin-top: 0px;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  box-shadow: inherit;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: var(--primary-color) !important;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  box-shadow: inherit;
}

.parent-container form input[type='button'] {
  padding: 10px 10px;
  cursor: pointer;
}

.parent-container .form-inputs input {
  font-size: 14px;
  padding-left: 42px;
  padding-left: 0px;
}

.parent-container .form-inputs {
  margin-bottom: 15px;
}

.parent-container .form-inputs label {
  font-size: 14px;
  /* font-family: 'poppinssemibold'; */
}

.parent-container .user-img-container {
  margin: 0px;
  text-align: left;
  display: inline-block;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;
}

.parent-container .user-img-container figcaption {
  margin-top: 10px;
}

.parent-container .user-img-container img {
  height: 180px;
  width: 180px;
  border-radius: 50%;
  object-fit: cover;
}

.check-wrap {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* border: 2px solid #00afcf; */
  position: relative;
  overflow: hidden;
  animation: wrap 0.3s ease-in-out forwards;
  animation-delay: 0.3s;
  transform: scale(0);
  margin: auto;
  background: var(--primary-gradient);
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 3.41772px 7.68987px 2.56329px rgba(97, 214, 221, 0.28);
}

.check-wrap::before,
.check-wrap::after {
  content: '';
  position: absolute;
  background-color: white;
  width: 0;
  height: 5px;
  transform-origin: left;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.check-wrap::before {
  top: 32px;
  left: 21px;
  transform: rotate(45deg);
  animation-name: left;
  animation-delay: 0.8s;
}

.check-wrap::after {
  top: 42px;
  left: 29px;
  transform: rotate(-45deg);
  animation-name: right;
  animation-delay: 1.1s;
}

@keyframes wrap {
  0% {
    background-color: transparent;
    transform: scale(0);
  }

  100% {
    background-color: var(--primary-gradient);
    transform: scale(1);
  }
}

@keyframes left {
  0% {
    width: 0;
  }

  100% {
    width: 15px;
  }
}

@keyframes right {
  0% {
    width: 0;
  }

  100% {
    width: 30px;
  }
}

.animate-success-container {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.animate-success-container h2 {
  margin: 16px 0px;
}

.animate-success-container .common-btn {
  padding: 10px 100px;
  /* margin-top: 10px; */
}

/*****************parent-form styling end********/

/***********************login form styling **************************/
.login-form-section .heading-container h2 {
  font-family: 'sorabold';
  color: #202020;
  margin-top: 10px;
  font-size: 36px !important;
}

.login-form-section .heading-container p {
  color: #2020207a;
}

.login-form-section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.login-form-container h3 {
  font-family: 'sorabold';
  font-size: 26px;
  /* margin-bottom: 24px; */
}

.login-form-container {
  box-shadow: 0px -4px 8px 4px rgba(0, 0, 0, 0.05), 0px 4px 8px 4px rgba(0, 0, 0, 0.05);
  padding: 54px 48px;
  background: #fff;
  box-shadow: 0px 5px 37px -15px rgba(19, 78, 82, 0.13);
  border-radius: 14px;
}

.login-form-container .form-group {
  margin: 0px;
}

.login-form-container .password-option-container label {
  margin: 0px;
}

.login-form-container .password-option-container a {
  color: #000;
  font-size: 14px;
}

.login-form-container .password-option-container {
  margin: 34px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
}

.login-form-container p {
  color: #4141417a;
  font-size: 16px;
}

.form-btn p {
  margin: 0px;
  font-size: 12px;
  margin-top: 14px;
  /* font-family: 'poppinssemibold'; */
}

/***************************login form styling  end**********************/
.option-main-container {
  position: relative;
}
.option-main-container .slick-arrow img {
  padding: 4px;
}
.option-main-container .match button {
  position: absolute;
  top: 0;
  display: block;
  font-size: 20px;
}
.refresh-btn button {
  display: none;
}
.option-main-container .slick-arrow {
  z-index: 9;
}

.option-main-container .slick-prev {
  left: 20px;
}

.option-main-container .slick-next {
  right: 20px;
}

.option-container {
  margin-top: 30px;
}
.chart-container canvas {
  height: 130px !important;
  width: 130px !important;
  margin: auto;
}
.option-main-container h3 {
  font-family: 'sorabold';
  text-align: left;
  /* font-size: 15px; */
  margin-bottom: 20px;
  color: #414141;
  opacity: 0.7;
  margin: 0px;
  font-size: 20px;
  /* font-family: 'soraregular'; */
  /* font-weight: 700; */
}

.option-main-container p:nth-child(2) {
  color: var(--secondary-color);
  text-align: left;
  font-size: 12px;
}

.option-main-container .option-container li {
  list-style: none;
  cursor: pointer;
  position: relative;
  padding: 30px;
  background: #fff;
  box-shadow: 0px 29px 65px -29px rgb(19 78 82 / 42%);
}

.option-main-container .option-container li.active h6 {
  color: var(--primary-color);
}

.option-main-container .option-container li.active .btn-container {
  display: block;
}

.option-main-container .option-container li .btn-container {
  display: none;
}

.option-main-container .option-container li .btn-container button {
  background: var(--primary-gradient);
  width: 100% !important;
  border: none;
  padding: 14px 10px;

  margin-bottom: 10px;
}

.option-main-container .option-container li .btn-container button:nth-child(2) {
  background: transparent;
  color: var(--primary-color);
  padding: 5px 0px;
  margin: 0px;
}

.option-main-container .option-container li figcaption {
  color: var(--primary-gradient);
  text-align: center;
}

.option-main-container .option-container li img {
  width: 100%;
  border-radius: 8px;
  /* height: 240px; */
  height: 180px;

  object-fit: contain;
}

.ecg-container {
  margin-right: 12px;
}

.ecg-container .downarrow {
  height: 23px !important;
  width: 23px !important;
  background-color: #21c167;
  padding: 7px;
  border-radius: 50%;
  text-align: center;
}

.ecg-container .text {
  background-color: #bd0809;
  color: #fff;
  font-size: 10px;
  padding: 4px;
  height: 23px;
  border-radius: 50%;
  width: 23px;
  text-align: center;
}

.option-main-container .option-container h6 {
  font-size: 20px;
}

.option-container p {
  color: #414141;

  font-size: 13px;
}

.breadcrumb {
  background-color: transparent;
  padding-left: 0px;
  padding: 0px;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

.bread-crumb-container .breadcrumb li a {
  color: var(--secondary-color);
  font-size: 22px;
  font-weight: 400;
}

.breadcrumb-item + .breadcrumb-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '';
  background-image: url('../images/breadcrumbarrow.svg');
  /* position: absolute; */
  height: 8px;
  opacity: 0.5;
  width: 4px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.breadcrumb-item.active {
  color: var(--primary-color);
  font-size: 11px;
}

/**/

/*live test btn*/
.live-test-btn {
  flex-direction: column;
}

.live-test-btn li {
  /* flex:0 0  80%; */
  width: 60%;
  padding: 10px 1;
}

.live-test-btn li button {
  width: 100%;
  padding: 14px 10px;
  border-radius: 2px;
}

/*live text btn end*/

/*dashboard styling*/
.profile-container {
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.05), 0px 4px 8px 2px rgba(0, 0, 0, 0.05);
  padding: 20px 20px;
  border-radius: 12px;
  background: #fff;
}

.profile-container .col-md-4 {
  text-align: center;
  border-right: 1px solid #c4c4c4;
}

.profile-container .col-md-4:last-child {
  border: none;
}

.profile-container h6 {
  font-family: poppinssemibold;
  font-size: 18px;
  color: #000;
}

.profile-container .dropdown button {
  background-color: transparent !important;
  border: none;
}

.profile-container .dropdown-toggle::after {
  display: none;
}

.profile-image {
  margin: 0px;
  padding: 0px;
}

.profile-container p {
  color: rgba(32, 32, 32, 0.4);
  margin: 0px;
}

.performance-container {
  text-align: center;
}

.performance-container .emotion-btn {
  background: #fff;
  border: none;
  width: 100%;
  margin-top: 22px;
  border: 1px solid var(--primary-gradient);
  color: var(--primary-gradient);
  padding: 12px 11px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.performance-container .emotion-btn:hover {
  background-color: var(--primary-gradient);
  color: #fff;
}

.performance-container .stat-container {
  margin-top: 19px;
}

.performance-container .state-item {
  margin-bottom: 40px;
}

.report-main-container {
  margin-top: 30px;
}

.notification-item button {
  padding: 0px;
}

.report-container h5 {
  text-align: center;
  margin: 0px;
  font-family: 'poppinssemibold';
  color: #000;
  font-size: 19px;
}

.taught-item img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.studentprofile p {
  margin: 0px;
}

.studentprofile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dashboard-heading h2 {
  margin: 0px;
  color: #414141;
  font-family: 'sorabold';
}

.dashboard-heading p {
  color: var(--secondary-color);
  font-family: 'soraregular';
  margin: 0px;
  font-size: 12px;
}

.dashboard-heading .notification-item {
  position: relative;
  height: 40px;
  height: 40px;
  width: 40px;
  padding: 8px;
  border-radius: 8px;
  margin-right: 10px;
  box-shadow: 0px 22px 25.6438px 2px rgba(0, 0, 0, 0.05);
}
.css-b62m3t-container {
  cursor: pointer !important;
}
.dashboard-heading .notification-item img {
  height: 100%;
  width: 100%;
}

.dashboard-heading .activity-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dashboard-heading .profile-item {
  padding: 10px 8px;
  border-radius: 8px;
  box-shadow: 0px 22px 25.6438px 2px rgb(0 0 0 / 5%);
}

.dashboard-heading .profile-item .toggle-container {
  padding: 0px;
}

.dashboard-heading .profile-item button {
  padding: 0px;
}

.dashboard-heading .profile-item figure {
  margin: 0px;
  display: flex;
  align-items: center;
  color: #000;
  gap: 7px;
  font-size: 12px;
  width: 100%;
}
.profile-image .content-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.dashboard-heading .profile-item img {
  height: 20px;
  width: 14px;
}

.dashboard-cards {
  background: #ffffff;

  box-shadow: 0px 5.17742px 38.3129px -24px rgba(19, 78, 82, 0.27);
}

.dashboard-cards {
  padding: 20px 30px;
  margin-top: 10px;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
}

.performance-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard-cards h4 {
  margin: 0px;
  font-size: 16px;
  position: relative;
  font-weight: 600;
}

.performance-heading p {
  font-size: 11px;
  margin: 0px;
  margin-top: 4px;
}

.performance-heading button {
  padding: 12px 30px;
}

.assignment-cards {
  background: var(--primary-gradient);
  /* border-radius: 16px; */
  padding: 16px 30px;
}

.assignment-cards h4 {
  color: #fff;
  position: relative;
  z-index: 3;
}

.assignment-cards .listing-container ul {
  padding: 0px;
}

.start-btnContainer {
  display: flex;
  align-items: center;
}

.start-btnContainer a {
  flex: 0 0 125px;
}

.start-btnContainer a:nth-child(1) button {
  padding-left: 0px;
  text-align: left;
}

.content h2 {
  font-family: 'sorabold';
  color: var(--secondary-color);
}

.content-container h2 {
  color: #000;
  opacity: 1;
}

.start-btnContainer a button {
  padding-left: 0px;
}

.assignment-cards .listing-container ul {
  /* justify-content: space-between; */
  align-items: center;
  gap: 10px;
}

.assignment-table-header figure {
  height: 100px;
  width: 100px;
}

.assignment-cards .assignment-table-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.assignment-table-header figure img {
  height: 100% !important;
  width: 100% !important;
}

.assignment-cards .listing-container ul li:nth-child(1) {
  color: #aef2eb;
}

.assignment-cards .listing-container ul li:nth-child(2) {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 6px;
}

.assignment-cards .listing-container ul li:nth-child(2) figure {
  color: #6bc3ba;
  margin: 0px;
  height: 25px;
  width: 25px;
  background: #fff;
  border-radius: 50%;
  /* padding: 3px; */
  text-align: center;
  line-height: 25px;
}

.assignment-cards .listing-container ul li:nth-child(2) figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.start-btn button {
  border: none;
  padding: 10px 12px;

  color: #fff;
  background: var(--primary-gradient);

  text-align: center;
}

.assignment-cards .listing-container ul li {
  list-style: none;
}

.dashboard-cards .blob-img {
  position: absolute;
  right: 0px;
  top: 0px;
  color: #6bc3ba;
}

.toggle-container::after {
  display: none;
}

.meeting-cards {
  padding: 14px 30px;
}

.dashboard-cards h6 {
  font-family: sorabold;
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 15px 0px;
  color: var(--secondary-color);
  opacity: 0.4;
}

.meeting-cards .listing-container ul figure img {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.meeting-cards .listing-container ul {
  padding: 0px;
}

.meeting-cards .listing-container ul:nth-child(2) {
  color: var(--secondary-color);
}

.meeting-cards .listing-container ul:nth-child(3) a {
  color: var(--primary-color);
}

.meeting-cards .start-btn {
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  display: flex;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  justify-content: center;
  text-align: center;
  gap: 4px;
  display: block;
}

.meeting-cards .start-btn img {
  margin-right: 10px;
}

/*.meeting-cards   .start-btn:hover{background:var(--primary-gradient);color:#fff;}
*/
.meeting-cards .listing-container ul figure {
  display: flex;
  align-items: center;
  gap: 4px;
}

.meeting-cards .listing-container ul li {
  list-style: none;
}

.pills-btn {
  padding: 0px;
  display: flex;
  font-size: 12px;
  gap: 10px;
  margin-top: 10px;
}

.pills-btn li {
  list-style: none;
  border: 1px solid #e6e6e6;
  color: #e6e6e6;
  padding: 4px;
  cursor: pointer;
  border-radius: 27px;
  font-family: 'soraregular';
  padding: 4px 10px;
  font-size: 10px;
}

.pills-btn li.active {
  background-color: #e4f4f2;
  color: var(--primary-color);
}

.percent-container h5 {
  font-size: 24px;
  color: #96c571;
}

.percent-container p {
  margin: 0px;
  color: rgba(54, 56, 83, 0.5);
  font-size: 12px;
}

.percent-container .span {
  color: #36385380;
}

.ct-golden-section {
  margin-bottom: 4px;
}

.chart-container {
  gap: 14px 0px;
  font-size: 14px;
  text-align: center;
  color: #c4c4c4;
  margin-top: 8px;
  text-transform: uppercase;
}

.ct-chart {
  margin-top: 10px;
}

.ct-vertical ~ .ct-vertical {
  stroke: none;
}

.ct-horizontal ~ .ct-horizontal {
  stroke: none;
}

.collapselink a {
  text-decoration: none;
}

.weekly-container {
  display: flex;
  align-items: center;
}

.weekly-container figure {
  margin: 0px;
  display: flex;
  align-items: center;
}

.weekly-container figcaption {
  margin: 0px 10px;
}

.weekly-container .arrow-container img {
  height: 14px;
  width: 14px;
  cursor: pointer;
}

.modal-dialogue-container {
  max-width: 1100px;
}

.modal-dialogue-container .modal-content {
  background-color: #000;
}

/*dashboard stlying end*/

/*reset password form design*/
.heading-container img {
  height: 265px;
}

.passwordresetform {
  width: 50%;
  margin: 30px auto 0px auto;
  padding: 0px;
}

.mobile-container span {
  position: absolute;
  bottom: 4px;
  color: #ebebeb;
}

.mobile-container {
  border-bottom: 2px solid #ebebeb;
  position: relative;
}

.mobile-container input {
  border: none;
  border-radius: 0px;
}

.communicationpage .slider-container .slideImage {
  margin: 0px;
  text-align: center;
  /* margin-top: 38px; */
}

.communicationpage .slider-container .slideImage .slick-slide div {
  height: 250px;
}

.communicationpage .slider-container figcaption {
  margin-top: 18px;
  margin-bottom: 16px;
  font-size: 16px;
}

.communicationpage .slider-container .slideImage figure img {
  width: 270px;
  height: 270px;
  object-fit: contain;

  padding: 36px;
}

.communicationpage .slider-container .slick-arrow img {
  height: 100% !important;
  width: 100% !important;
  padding: 0px !important;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

/* .result-container .loader-container {
  display: none
} */

.communicationpage .slider-main-container {
  position: relative;
}

.communicationpage .slider-main-container .arrow-container .prevArrow {
  left: 16%;
}

.communicationpage .slider-main-container .arrow-container .nextArrow {
  right: 8%;
}

.communicationpage .slider-main-container .arrow-container figure {
  margin: 0px;
  height: 38px;
  width: 38px;
  position: absolute;
  border: 1px solid rgba(32, 32, 32, 0.2);
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.communicationpage .slider-main-container .arrow-container figure img {
  height: 100%;
  width: 100%;
  padding: 8px;
}

.result-container button {
  padding: 9px 52px;
  scroll-snap-align: center;
  border: none;
  background-color: transparent;
  color: var(--primary-color);
  padding: 0px;
  justify-content: center;
  font-family: 'sorabold';
  display: flex;
  align-items: center;
  gap: 5px;
}
.result-container {
  /* flex: 0 0 56%; */
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 14px;
}
.result-container select {
  border: none;
  cursor: pointer;
}
.result-container button img {
  height: 24px;
  width: 24px;
}

.result-container figure {
  margin: 0px;
  flex: 0 0 170px;
  padding: 0px 10px;
}
.result-container figure img {
  height: 240px;
  width: 240px;
}

/* .apple-container {
  display: none
} */

.communicationpage .btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  margin-top: 18px;
}

.communicationpage .btn-container select {
  background-color: transparent;
  border: none;
  width: 102px;
  background: transparent;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 4px 7px;
  height: 32p;
}

.refresh-btn button {
  border: none;
  color: var(--primary-color);
  background-color: transparent;
  /* border: 1px solid var(--primary-color); */
  border-radius: 8px;
  padding: 7px 10px;
}

.refresh-btn button img {
  height: 15px;
  width: 15px;
  margin-right: 5px;
}

.refresh-btn p {
  margin: 0px;
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
}

.refresh-btn p span {
  background-color: green;
  height: 8px;
  width: 8px;
  /* flex: 0 0 10px; */
  display: inline-block;
  border-radius: 50%;
  margin-right: 4px;
}

.chart-container h5 {
  font-size: 14px;
  text-align: center;
  color: #c4c4c4;
  margin-top: 8px;
  /* margin-bottom: 30px; */
}

.back-btn-container {
  flex: 1;

  display: flex;
  align-items: center;
  gap: 10px;
}

/*communication page styling*/
.communication-heading {
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}

.communication-heading img {
  height: 25px;
  width: 25px;
}

.start-container p {
  color: var(--secondary-color);

  /* padding: 0px px 111px; */
}

.communication-heading h2 {
  margin: 0px;
  font-size: 20px;
}

.communicationpage .category-txt {
  text-align: left;

  margin-top: 35px;
}

.start-container p {
  color: var(--secondary-color);

  /* padding: 0px 111px; */
}

.start-container button {
  padding: 10px 44px;
}

.bread-crumb-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 1px;
}

.select-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-main-container .select-container {
  text-align: left;
  margin-left: 10px;
}

.select-main-container .select-container .profile-container {
  padding: 10px 8px;
  border-radius: 5px;
  height: inherit;
  margin-top: 4px;
}

.communicationpage .category-txt label {
  /* flex:1; */
  margin: 0px;
  color: #000;
  font-weight: 700;
}

.flex-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.communicationpage .category-txt select {
  width: 88px;
  background: transparent;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 4px 7px;
  height: 32px;
}

.categoryslider-container {
  border-right: 1px solid rgba(32, 32, 32, 0.1);
}

.communicationpage .slider-container .slideImage {
  margin: 0px;
  padding: 5px;
  text-align: center;
  background-color: #fff;
  margin-top: 38px;
  margin: 31px auto 0 auto;
}

.communicationpage .slider-container figcaption {
  margin-top: 18px;
  margin-bottom: 16px;
  font-size: 16px;
}

.communicationpage .slider-container {
  text-align: center;
}

.communicationpage .slider-container figure {
  border: 1px dashed #6ec4bb;
  height: 270px;
  width: 270px;
  border-radius: 50%;
  margin: auto;
}

.communicationpage .slider-container figure.slick-prev {
  left: -20%;
}

.slider-container .slick-list {
  border-radius: 50%;
}

.communicationpage .slider-container figure.slick-next {
  right: -35%;
}

.communicationpage .slider-container figure img {
  width: 100%;
  height: 100%;
  padding: 32px;
  cursor: pointer;
  cursor: pointer;
  object-fit: cover;
}

.ct-grid {
  display: none;
}

/* .result-container .loader-container {
  display: none
} */

.communicationpage .slider-main-container {
  position: relative;
}

.arrow-item .prevArrow {
  left: 25px;
}

.arrow-item .nextArrow {
  right: 8px;
}

.ct-label {
  font-size: 9px !important;
}

.slick-arrow img {
  height: 100%;
  width: 100%;
  padding: 8px;
}

.result-container button {
  padding: 9px 52px;
}

.result-container figure {
  margin: 0px;
}

.result-container figure img {
  height: 240px;
  width: 240px;
}

/* .apple-container {
  display: none
} */

.communicationpage .btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  gap: 24px;
}

.communicationpage .btn-container select {
  background-color: transparent;
  border: none;
  width: 102px;
  background: transparent;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 4px 7px;
  height: 32p;
}

.slick-arrow {
  margin: 0px;
  height: 38px !important;
  width: 38px !important;
  position: absolute;
  border: 1px solid rgba(32, 32, 32, 0.2);
  border-radius: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  border: 1px solid var(--primary-color) !important;
  padding: 7px !important;
}

.refresh-btn button {
  border: none;
  color: #000;
  font-size: 14px;
  background-color: transparent;
  border-radius: 8px;
  padding: 7px 10px;
  color: var(--secondary-color);
}

.refresh-btn button img {
  height: 14px;

  width: 14px;
}

.refresh-btn p {
  margin: 0px;
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
}

.refresh-btn p span {
  background-color: green;
  height: 8px;
  width: 8px;
  /* flex: 0 0 10px; */
  display: inline-block;
  border-radius: 50%;
  margin-right: 4px;
}

.back-btn-container {
  flex: 1;
  /*communication page styling*/
  display: flex;
  align-items: center;
  gap: 10px;
}

/*game css*/
.game-card-container .game-items {
  padding: 14px 10px;
  border-radius: 12px;
  background-color: #fff;
  margin-top: 23px;
}

.game-items figcaption {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.game-card-container .game-items {
  display: flex;
  gap: 10px;
  margin: 0px;
  box-shadow: 0px 5px 37px -15px rgba(19, 78, 82, 0.13);
}

.game-card-container .game-items h3 {
  font-size: 18px;
  font-family: 'sorabold';
  text-transform: capitalize;
}

.game-card-container .game-items p {
  color: rgba(32, 32, 32, 0.6);
  /* margin: 0px; */
  font-size: 12px;
  margin: 0px;
}

.game-card-container .game-items img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.start-btnContainer .view-report button {
  color: var(--primary-color);
}

.game-card-container .game-items button {
  background-color: #fff;
  padding: 9px 15px;
  border: navajowhite;
  border-radius: 8px;
  font-size: 11px;
  width: 100%;
}

.game-card-container .game-items img {
  height: 100px;
  width: 100px;
  border-radius: 10px;
  object-fit: cover;
}

.daily-activities {
  background-color: #c8c8fa;
}

/*testform styling*/
.mathsform img {
  margin-bottom: 17px;
}

/*addiiton-container*/
.question-card {
  display: flex;
  align-items: flex-start;
  position: relative;
}
.quizes_report_table td .colors-main-container {
  margin-top: 40px;

  position: relative;
}

.colors-main-container figure {
  text-align: center;
}

.colors-main-container p {
  color: var(--secondary-color);
  word-wrap: break-word;
}
.wpm textarea {
  text-align: left !important;
}
.color-preview {
  box-shadow: 0px 9.56974px 11.1548px 0.869976px rgb(0 0 0 / 5%);
  padding-top: 80px;
  /* height: 400px; */
  /* padding: 30px; */
  width: 25%;
  margin: auto;
  /* border-radius: 8px; */

  background: #fff;
}

.activity-listing ul {
  padding: 0px;
  display: flex;
  color: var(--secondary-color);
  justify-content: space-between;
  margin-top: 20px;
}

.activity-listing ul li {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 10px;
}

.activity-listing ul li.active span {
  color: var(--secondary-color);
}

.addition-container {
  /* padding: 30px; */
  /* background-color: #fff; */
  text-align: right;
  /* margin: 85px 0px; */
  /* margin-top: 80px; */
  border-bottom: 2px dashed var(--primary-color);
  /* height: 400px; */
  padding-right: 60px;
}

.addition-container p {
  text-align: center;
}

.removebtn {
  color: #c36b6b;
}

.absolute-btn.removebtn {
  right: 53px;
}

.findmetxt {
  text-align: center;
  color: var(--secondary-color);

  font-family: sorabold;
  margin-top: 20px;
}

.absolute-btn.right {
  right: 18px;
  color: var(--primary-color);
}

.absolute-btn {
  position: absolute;
  top: 26px;
  font-size: 12px !important;
}

.addition-container + p {
  margin: 0px;
  text-align: center;
  margin-top: 9px;
  font-size: 20px;
  font-family: 'sorabold';
}

.question-card + p {
  margin: 0px;
  text-align: center;
  margin-top: 9px;
  font-size: 20px;
  font-family: 'sorabold';
}

.question-card + p span {
  color: #33b61e;
}

.addition-container + p span {
  color: #33b61e;
}

.addition-container h5 {
  font-size: 80px;
}

.equalto {
  font-size: 61px;
  margin: 0px;
  text-align: center;
}

.measure-container .measure-item {
  text-align: center;
  padding: 16px;
  transition: 1s all;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(50% - 10px);
  margin-bottom: 20px;
  padding: 16px 24px;
  /* border: 4px solid rgba(0, 0, 0, 0.1); */
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 22px 25.6438px 2px rgb(0 0 0 / 5%);
  margin: 5px auto 14px auto;
  /* text-align: center*/
}

.measure-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.measure-container .measure-item h5 {
  margin: 0px;
  font-size: 60px;
  font-family: 'sorabold';
  line-height: 74px;
}

.operatortxt {
  color: var(--primary-color);
  opacity: 0.4;
  position: absolute;
  left: 18%;
}

.english-main-container {
  background-color: #fff;
  /* color: #000; */
  text-align: center;
  padding: 10px;
  font-size: 30px;
  color: var(--secondary-color);
  text-align: center;
  box-shadow: 0px 18.0251px 21.0105px 1.63864px rgb(0 0 0 / 5%);
  width: 30%;
  margin-left: auto;
  border-radius: 15px;
  margin-top: 12px;
}

.score-txt {
  font-size: 25px;
  margin-bottom: 31px;
  font-weight: 700;
  border-radius: 12px;
}

.measure-container .measure-item:hover {
  background-color: var(--primary-color) !important;
  color: #fff;
}

.dropdown-toggle::after {
  display: none !important;
}

/*colors box styling*/
.color-main-container {
  padding: 18px 36px;
}

.colors-main-container .question-card .activity-listing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 0px;
  margin-top: 17px;
  margin-bottom: 20px;
}

.colors-main-container .question-card .activity-listing ul {
  padding: 0px;
  display: flex;
  align-items: center;
  gap: 2px;
  margin: 0;
  margin-bottom: 14px;
  margin-bottom: 0px;
}

.colors-main-container .question-card .activity-listing ul li {
  list-style: none;
}

.colors-main-container .color-preview {
  padding: 28px 10px;
}

.colors-main-container .color-preview h6 {
  color: green;
  font-size: 30px;
  text-transform: uppercase;
}

.question-card h6 {
  text-align: center;
  margin: 0px;
}

.question-card h3 {
  text-align: center;

  font-weight: 800;
}

.option-color-box {
  margin-top: 20px;
}

.option-color-box .box-item {
  cursor: pointer;

  text-align: center;
  padding: 58px 30px;
  border-radius: 10px;
  color: #fff;
  font-size: 50px;
  /* justify-content: center; */
}

.option-color-box .box-item p {
  margin: 0px;
  text-transform: uppercase;
  color: #fff;
}

.option-color-box .box-item.bg-red {
  background-color: #d34344;
}

.option-color-box .box-item.bg-blue {
  background-color: #00bce5;
}

.option-color-box .box-item.bg-green {
  background-color: #08a806;
}

.option-color-box .box-item.bg-purple {
  background-color: #a1a1ff;
}

.focus-txt {
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  font-family: 'sorabold';
}

.focus-txt span {
  color: #d34344;
}

.ct-slice-donut {
  stroke-width: 30px;
}

.notificationdot {
  background-color: red;
  border-radius: 50%;
  height: auto;
  width: 18px;
  font-size: 12px;
  color: #fff !important;
  position: absolute;
  top: -18px;
  right: -8px;
}

.score {
  position: absolute;
  top: 34%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 63px;
}

.absolutetext {
  position: absolute;
  top: 26%;
  right: 3%;
  border-radius: 10px;
  color: #000;
  width: 40%;
  border-radius: 14px;
  overflow: hidden;
}

.emotionstatetable {
  border: 1px solid #000;
  width: 100%;
}

.emotionstatetable tr {
  padding: 10px;
  border: 1px solid #fff;
}

.emotionstatetable tr:nth-child(2n + 1) {
  background-color: #c4c4c4;
  color: #fff;
}

.emotionstatetable tr td {
  border: 1px solid #fff;
  padding: 10px;
}

.communicationpage .slider-container figure .slick-slide.fullscreen img {
  height: 900px;
  width: 900px;
  object-fit: contain;
}

.communicationpage .slider-container figure .slick-slide.fullscreen div[tabindex='-1'] {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff;
}

.communicationpage .slider-container figure .slick-slide.fullscreen p {
  display: block;
  position: absolute;
  top: 16px;
  left: 0px;
  font-size: 45px;
  left: 22px;
  opacity: 0.2;
}

:fullscreen .communicationpage .slider-container figure .slick-slide.fullscreen img {
  background-color: #fff;
  height: 49% !important;
  object-fit: contain;
}

.colors-main-container figcaption {
  color: var(--primary-color);
  font-weight: 800;
  text-transform: uppercase;
  font-size: 30px;
  margin-top: 30px;
  text-align: center;
}

.colors-main-container img {
  height: 400px;
  width: 400px;
}

.absolutetext h5 {
  text-align: center;
  color: var(--primary-color);
  text-transform: uppercase;
  margin-bottom: 20px;
}

.disable-btn {
  background: radial-gradient(98.07% 73.14% at 3.51% 19.05%, #efefef 5.73%, #d6d6d6 100%);
  box-shadow: inherit;
}

.edit-box {
  position: relative;
}

.edit-box figcaption {
  position: absolute;
  background: #fff;
  padding: 4px;
  line-height: 0px;
  /* padding: 3px 6px; */
  border-radius: 50%;
  background: #fff;
  /* overflow: hidden; */
  top: 58px;
  left: 68%;
}

.edit-box i {
  height: 20px;
  text-align: center;
  width: 20px;
  background: var(--primary-color);
  line-height: 22px;
  border-radius: 50%;
  color: #fff;
  font-size: 9px;
}

.check-wrap {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  /* border: 2px solid #00afcf; */
  position: relative;
  overflow: hidden;
  animation: wrap 0.3s ease-in-out forwards;
  animation-delay: 0.3s;
  transform: scale(0);
  margin: auto;
  background: var(--primary-gradient);
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 3.41772px 7.68987px 2.56329px rgba(97, 214, 221, 0.28);
}

.check-wrap::before,
.check-wrap::after {
  content: '';
  position: absolute;
  background-color: white;
  width: 0;
  height: 5px;
  transform-origin: left;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

.check-wrap::before {
  top: 32px;
  left: 21px;
  transform: rotate(45deg);
  animation-name: left;
  animation-delay: 0.8s;
}

.check-wrap::after {
  top: 42px;
  left: 29px;
  transform: rotate(-45deg);
  animation-name: right;
  animation-delay: 1.1s;
}

@keyframes wrap {
  0% {
    background-color: transparent;
    transform: scale(0);
  }

  100% {
    background-color: var(--primary-gradient);
    transform: scale(1);
  }
}

@keyframes left {
  0% {
    width: 0;
  }

  100% {
    width: 15px;
  }
}

@keyframes right {
  0% {
    width: 0;
  }

  100% {
    width: 30px;
  }
}

.animate-success-container {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.animate-success-container h2 {
  font-family: 'sorabold';
  margin: 0px;
  font-size: 30px !important;
}

.animate-success-container p {
  color: var(--secondary-color);
}

.common-btn a {
  color: #fff;
}

.animate-success-container .common-btn {
  padding: 10px 100px;
  /* margin-top: 10px; */
}

.back-container h3 {
  margin: 0px;
  text-transform: capitalize;
  font-size: 20px;
  font-family: sorabold;
}

.back-container img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  margin-right: 10px;
  border-radius: 50%;
}

.back-container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.txt-secondary {
  color: var(--secondary-color);

  line-height: 40px;
  font-size: 14px;
}

.dzu-dropzone {
  min-height: 100px !important;
  height: auto;
  background: var(--primary-gradient);
  overflow: auto;
  border: none;
}
.dzu-dropzone .dzu-inputLabel {
  color: #fff;
  font-size: 22px;
}
.dzu-dropzone .dzu-previewContainer {
  margin: 0;
  padding: 10px 3%;
}

.dzu-dropzone .dzu-previewContainer .dzu-previewFileName {
  width: 50%;
  word-wrap: break-word;
}
.dzu-dropzone .dzu-inputLabelWithFiles {
  margin: 10px 3%;
}

/* result styling */
.resultCard .row {
  margin-top: 30px;
}

.resultCard h4 {
  text-align: left;
  color: #000;
}

.resultCard figure {
  /* background-color: #e4f4f2; */
  border-radius: 50%;

  padding: 30px;
  margin-top: 30px;
}

.resultCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.pagination-container p {
  margin: 0px 20px;
}

.pagination-container figure:nth-child(1) {
  transform: rotate(180deg);
}

.pagination-container figure {
  height: 30px;
  width: 30px;
  margin: 0px;
  background-color: #fff;
  cursor: pointer;
  padding: 8px;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 3px 7px -3px;
  line-height: 0;
}

.pagination-container figure img {
  height: 100%;
  object-fit: inherit;
  width: 100%;
}

.back-container {
  margin-bottom: 30px;
  margin-top: 12px;
}

.back-container i {
  font-size: 30px;
  color: var(--secondary-color);

  margin-right: 10px;
}

.students-container a {
  color: #000;
}

/* result styling */

/* educationCard */
.educationCard .card {
  border: none;
  box-shadow: 0px 5.17742px 38.3129px -15.5323px rgba(19, 78, 82, 0.27);
}

.educationCard .card {
  text-align: center;
  border-radius: 16px;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.educationCard .card img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.educationCard figure {
  margin: 0px;
  position: relative;
}

.educationCard .attempttxt {
  background-color: #00000030;
  position: absolute;
  top: 14px;
  left: 14px;
  font-size: 13px;
  padding: 3px 10px;
  border-radius: 30px;
  color: #fff;
  font-weight: 800;
}

.educationCard .attempttxt.new {
  background-color: rgba(255, 184, 0, 0.58);
}

.educationCard .btn-container {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.educationCard .btn-container .activbtn:nth-child(1) {
  border: 0px;
}

.educationCard .btn-container .activbtn {
  border: 1px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  margin-top: 20px;
  white-space: nowrap;
  font-size: 14px;
  padding: 12px 10px;
  border-radius: 10px;
  flex: 0 0 calc(50% - 10px);
}

.secondary-txt {
  color: var(--secondary-color);

  margin-top: 10px;
  text-align: center !important;
  display: block;
}

/* .result-table img {
  height: 50px;
  width: 50px;
  object-fit: cover;
} */

.table-container {
  margin-top: 30px;
  overflow: auto;
}

.result-table thead {
  border: none;
}

.result-t::-webkit-scrollbar {
  display: none;
}
.result-table th {
  border: none;
  font-size: 12px;
  text-align: center;
  color: var(--secondary-color);

  font-weight: 700;
}

.result-table tr td:nth-child(1) {
  padding: 40px 10px;
  font-weight: 700;
  color: var(--secondary-color);

  font-size: 12px;
}

.result-table tr {
  border: none;
  border-style: hidden;
}

.result-table td {
  padding: 28px 24px;
  border: 2px dashed #4141411a;
}

.result-table th {
  border: 2px dashed #4141411a;
}

.tickContainer {
  background: radial-gradient(70.7% 70.7% at 10.55% 23.05%, #d9ffbb 0%, #abd888 100%);
  color: #fff;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  line-height: 35px;
  font-size: 15px;
  margin: auto;
}

.report-container p {
  color: var(--primary-color) !important;
  border: 1px solid#41414159;
  padding: 4px 10px;
  border-radius: 30px;
  margin: 0px;
}

.report-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-container p span {
  color: #41414159;
}

.crossContainer {
  background: radial-gradient(73.44% 73.44% at 23.05% 10.55%, #ffced2 0%, #eb7e87 100%);
  color: #fff;
  height: 30px;
  margin: auto;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  font-size: 18px;
}

/* educationCard end




/*progree stlyling */
.ProgressBar {
  margin: 0 auto;
  padding: 0px;
  list-style: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.ProgressBar-step {
  text-align: center;
  position: relative;
  width: 100%;
}

.ProgressBar-step:before,
.ProgressBar-step:after {
  content: '';
  position: absolute;
  z-index: 1;
  width: 100%;
  border: 1px dashed var(--secondary-color);
  left: -108%;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.25s ease-out;
}

.ProgressBar-step:first-child:before,
.ProgressBar-step:first-child:after {
  display: none;
}

.ProgressBar-step:after {
  border: 1px solid var(--primary-color);
  width: 0%;
}

.ProgressBar-step.is-complete + .ProgressBar-step.is-current:after,
.ProgressBar-step.is-complete + .ProgressBar-step.is-complete:after {
  width: 200%;
}

.ProgressBar-icon {
  width: 30px;
  height: 30px;
  background-color: #e0e0e0;
  fill: #9f9fa3;
  border-radius: 50%;
  padding: 0.5em;
  max-width: 100%;
  z-index: 10;
  position: relative;
  transition: all 0.25s ease-out;
  margin: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.is-current .ProgressBar-icon {
  background: #fff;
  color: var(--primary-color);
  border: 2px solid;
}

.is-complete .ProgressBar-icon {
  color: #fff;
  background: radial-gradient(70.7% 70.7% at 10.55% 23.05%, #d9ffbb 0%, #abd888 100%);
}

.is-wrong .ProgressBar-icon {
  color: #fff;
  background: radial-gradient(73.44% 73.44% at 23.05% 10.55%, #ffced2 0%, #eb7e87 100%);
}

.wrapper {
  max-width: 1000px;
  font-size: 16px;
}

/* progress styling */

.asked-color-box {
  box-shadow: 0px 4.89258px 5.70293px 0.44478px rgba(0, 0, 0, 0.05);
  color: #77c63a;
  text-align: center;
  text-transform: uppercase;
  text-align: center;
  padding: 5px 8px;
  border-radius: 5px;
  justify-content: center;
  position: relative;
}

.asked-color-box p {
  margin: 0px;
  white-space: nowrap;
  font-size: 12px;
  padding: 7px 5px;
}

.answered-color-box {
  background-color: #ea5656;
  color: #fff !important;

  position: relative;
  text-align: center;
}

.answered-color-box p {
  text-align: center !important;
  color: #fff !important;
}

.answered-color-box span {
  position: absolute;
  font-size: 10px;
  background-color: #fff;
  color: #ea5656;
  padding: 4px 4px;
  border-radius: 50%;
  right: -8px;
  top: -13px;
}

.answered-color-box.green {
  background-color: #77c63a;
}

.answered-color-box.green span i {
  background-color: #77c63a;
}

.ripple-wave {
  height: inherit !important;
  min-width: inherit !important;
  width: inherit !important;
}
.ripple-surface {
  /* width: inherit !important; */

  min-width: inherit !important;
}

.answered-color-box span i {
  background-color: #ea5656;
  color: #fff;
  padding: 2px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
}
.loader-component {
  position: fixed;
  height: 100vh;
  width: 100%;
  left: 0;
  z-index: 9999;
  background: #fff;
  top: 0;
}
.option-main-container .option-container li {
  margin-bottom: 62px;
}

.join-container {
  text-align: center;
}

.meet-table-container .parent-container {
  display: flex;
  align-items: center;
}

.meet-table-container .parent-container p {
  margin: 0px;
}

.meet-table-container .parent-container img {
  border-radius: 5px;
  height: 28px;
  margin-right: 10px;
  width: 28px;
}

.option-main-container .option-container li.active {
  display: block;
}

.join-container a {
  color: var(--primary-color);
}

.option-main-container .option-container li .btn-container {
  display: none;
}

.meet-table-container .timing-container i {
  color: var(--primary-color);
  margin-right: 6px;
}

.meet-table-container th {
  text-transform: uppercase;
  letter-spacing: 5px;
  white-space: nowrap;
  font-size: 12px;
}

.meet-table-container .join-container p {
  color: var(--primary-color);
}

.meet-table-container td {
  width: 18%;
}

.join-container button {
  margin: 0px;
  padding: 4px;
  width: inherit;
  display: block;
  margin: auto;
  padding: 2px 75px;
  margin-top: 8px;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 3.79909px 5.69863px -0.949771px rgba(0, 0, 0, 0.12),
    0px 1.89954px 3.79909px -0.949771px rgba(0, 0, 0, 0.07);
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
  gap: 20px;
}

.pro-sidebar a {
  color: var(--secondary-color);
}

.pro-sidebar a.active .pro-icon-wrapper {
  background-color: var(--primary-color) !important;
}

.pro-inner-item > a.active {
  color: #000;
}

.pro-item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* english comp css */
.english-main-container {
  width: auto;
  padding: 7px 46px;
  margin: auto;
}

.english-container {
  display: flex;
  align-items: flex-end;
}

.english-container figcaption {
  font-size: 30px;
  font-family: 'sorabold';
  text-transform: uppercase;
}

.english-container input {
  flex: 1;
  border-bottom: 2px solid var(--primary-color);
}

.english-container figure {
  margin: 0px;
  text-align: center;
  flex: 0 0 48%;
}

.english-main-container .option-container {
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-around;
  gap: 13px;
}

.english-main-container .option-container h5 {
  font-size: 25px;
  font-family: sorabold;
  cursor: pointer;
  border: 4px solid var(--primary-color);
  padding: 20px;
  border-radius: 14px;
  flex: 0 0 50%;
  text-transform: uppercase;
}

.english-main-container .option-container h5:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.english-container figure img {
  height: 150px;
  width: 150px;
  object-fit: cover;
}

.floating-question-container {
  flex-direction: column;
  position: relative;
  padding-bottom: 80px;
}

.floating-question-container .english-container {
  flex-direction: column;
  flex-flow: column-reverse;
  align-items: center;
}

.floating-question-container .english-container input {
  border-bottom: 2px solid var(--primary-color);
  width: 150px;
}

/* .floating-question-container .option-container h5 {
  position: absolute;
} */
.animate .sidebar-header {
  padding: 18px 4px;
  justify-content: center;
}
.color {
  text-align: center;
  padding: 15px 10px;
  width: 30%;
  margin-left: auto;
  box-shadow: 0px 22px 25.6438px 2px rgb(0 0 0 / 5%);
  border-radius: 10px;
  font-size: 37px;
  margin-right: 40px;
}
.content {
  margin-left: inherit;
}

/* .floating-question-container .option-container h5:nth-child(1) {
  left: 100px;
  top: 50px;
}

.floating-question-container .option-container h5:nth-child(2) {
  right: 100px;
  top: 50px;
} */

.floating-question-container .option-container h5:nth-child(3) {
  bottom: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timerModal .modal-content {
  background-color: transparent;
  border: none;
  font-size: 16px;
}

.timerModal .modal-content div:nth-child(1) {
  margin: auto;
}

.timerModal .modal-content .modal-dialog {
  height: 80vh;
  display: flex;
}

.activity-listing div {
  font-size: 12px;
}

.chartcolor .ct-series-a .ct-line,
.ct-series-a .ct-point {
  stroke: blue;
}

.chartcolor .ct-series-b .ct-line,
.ct-series-b .ct-point {
  stroke: green;
}

.identity-container {
  margin: 0px;
}

.identity-container img {
  height: 200px;
  width: 200px;

  object-fit: cover;
}

/* .report-modal .modal.show .modal-dialog {
  max-width: 650px !important;
} */
.report-modal .modal-content {
  border-radius: 15px;
}

.reportContainer {
  padding: 6px 16px;
}

.reportContainer .box-item {
  margin-bottom: 20px;
}

.reportContainer .box-item p {
  margin: 0px;
  color: var(--secondary-color);
  font-size: 14px;
}

.reportContainer .card-header {
  display: flex;
  gap: 10px;
  border: none;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.reportContainer .card-header h4 {
  margin: 0px;
  opacity: 1 !important;
  font-size: 15px !important;
}

.reportContainer .card-header h4 {
  font-size: 18px;
  color: #54baaf;
  font-weight: 600;
}

.reportContainer .box-item {
  color: #54baaf;
  border-radius: 14px;
}

.reportContainer .card-header h1 {
  font-weight: 800;
}

.reportContainer .card-header h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800;
}

.reportContainer .box-item h1 {
  font-size: 20px;
  text-align: center;
  font-weight: 800;
  opacity: 0.7;
}

.reportContainer .absolutediv {
  position: relative;
  text-align: center;
}

.reportContainer .box-item h2 {
  font-size: 25px;
  opacity: 0.7;

  opacity: 0.9;
}

.reportContainer .box-item h3 {
  font-size: 14px;
  opacity: 0.7;
  position: absolute;
  right: 10px;
}

.reportContainer .box-item h4 {
  font-size: 11px;
  opacity: 0.5;
}

.reportContainer .box-item h5 {
  font-size: 11px;
  opacity: 0.3;
  position: absolute;
  margin: 0px;
  left: 10px;
  top: 10px;
}

.reportContainer .box-item h6 {
  font-size: 12px;
  opacity: 0.4;
  position: absolute;
  left: 10px;
  top: 68px;
}

.gameCatContainer .card .card-title {
  text-transform: capitalize;
}

.gameCatContainer .card img {
  height: 200px;
  width: 100%;
  object-fit: cover;
}

.report-tab .nav-tabs {
  border-bottom: transparent;
  font-weight: 300;
}

.report-tab .nav-link.active {
  color: #54baaf;
  background-color: transparent;
  border-top: #ffffff;
  border-right: #ffffff;
  border-left: #ffffff;

  border-bottom: 2px solid #54baaf;
}
.week-report button {
  white-space: nowrap;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 13px;
}
.data-table-extensions-action {
  display: none;
}
.report-tab .nav-link {
  width: 100% !important;
  color: var(--secondary-color);
}
.download-drop {
  background: #f7ffff;
  border: 2px solid #00bdb2;
  padding: 10px;
  color: #12070738;
  font-weight: 700;
  cursor: pointer;
}
.score-badge span {
  color: #54baaf;
  font-weight: 300;
}

.score-badge p {
  color: var(--secondary-color);
}

.score-badge {
  border: 1px solid #54baaf;
  border-radius: 50px;
  text-align: center;
  width: 55px;
  margin-top: 16px;
}

.report-button {
  /* width: 200px; */
  padding: 12px 10px;
  width: inherit;
  font-size: 12px;
}
.report-button:hover {
  color: #fff;
}

.report-datatable td {
  color: var(--secondary-color);
  padding: 34px 10px;
  vertical-align: middle;
  text-align: center;
}

/* .report-datatable {
  color: green;
} */
.week-report {
  position: absolute;
  top: 145px;
  gap: 11px;
  justify-content: center;
  display: flex;
  right: 25px;
}

.btn-week {
  color: #399399;
  background-color: #e3f4f2;
  border-radius: 50px;
}

.btn-month {
  color: var(--secondary-color);
  background-color: #80808069;
  border-radius: 50px;
}

.btn-month:active {
  color: #399399;
  background-color: #e3f4f2;
}
.disable-btn {
  padding: 16px 121px;
}

.result-table tr h4 {
  color: var(--secondary-color);
  font-size: 12px;
  display: inherit;
  padding-left: 5px;
}

.share-dots {
  color: #a6a2a2;
  vertical-align: middle;
}

.btn-all {
  /* right: 22%;
  top: 83px; */
  color: #399399;
  background-color: #e3f4f2;
  border-radius: 50px;
}

.btn-allweek {
  /* position: absolute;
  right: 13%;
  top: 83px; */
  background-color: #80808069;
  border-radius: 50px;
}

.btn-allmonth {
  /* position: absolute;
  right: 4%;
  top: 83px; */
  background-color: #80808069;
  border-radius: 50px;
}

.btn-allweek:active {
  color: #399399;
  background-color: #e3f4f2;
}

.btn-allmonth:active {
  color: #399399;
  background-color: #e3f4f2;
}

.result-container .dropdown-menu {
  max-height: 265px;
  overflow-y: scroll;
}

.report-category {
  display: flex;
  justify-content: space-between;
}

/* new page style */
.comparing-game .addition-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px;
  border: none;
}

.comparing-game .question-container {
  margin: 0px;
  background-color: #fff;
}
.content {
  margin-left: 20px;
}
.comparing-game .content-container {
  margin-top: 30px;
}
.content {
  min-height: inherit;
}
.comparing-game .content-container p {
  margin: 0px;
}

.comparing-game .measure-container .measure-item {
  padding: 46px 10px;
}

.comparing-game .measure-container .measure-item h5 {
  line-height: 0px;
}

/* radio btn csss */
.button {
  float: left;
  margin: 0 5px 0 0;

  position: relative;
}

.button label,
.button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.button input[type='radio'] {
  opacity: 0.011;
  z-index: 100;
}

.crossContainer input[type='radio']:checked + label {
  background: radial-gradient(73.44% 73.44% at 23.05% 10.55%, #ffced2 0%, #eb7e87 100%);
}

.tickContainer input[type='radio']:checked + label {
  background: radial-gradient(70.7% 70.7% at 10.55% 23.05%, #d9ffbb 0%, #abd888 100%);
}

.button label i {
  color: #fff;
}

.button label {
  background: radial-gradient(98.07% 73.14% at 3.51% 19.05%, #efefef 5.73%, #d6d6d6 100%);
  text-align: center;
  cursor: pointer;
  padding: 0px;
  border-radius: 50%;
  line-height: 30px;
}

/* radio btn cc end */
.report-datatable .sc-gsnTZi:first-child {
  display: none;
}

.report-datatable .sc-jqUVSM:nth-child(even) {
  background-color: #f2ffff80;
  border: none;
}

/* .sc-hKMtZM[data-column-id='6'] {
  flex: 0 0 20%;
  justify-content: flex-end;
}

.sc-hKMtZM[data-column-id='6'] [data-tag='allowRowEvents'] {
  width: 100%;
}

.sc-hKMtZM[data-column-id='6'] [data-tag='allowRowEvents'] a {
  width: 100%;
}

.sc-hKMtZM[data-column-id='7'] {
  flex: 0 1;
  justify-content: flex-end;
  min-width: inherit;
} */

.option_img img {
  height: 150px;
  width: 150px;
  object-fit: cover;
}

.ex-modal p {
  color: #c4c4c4;
  font-size: 12px;
}

.ex-modal .modal-content {
  border-radius: 14px;
}

.ex-modal span {
  background: radial-gradient(98.07% 73.14% at 3.51% 19.05%, #ffe1bd 8.51%, #da8522 100%);
  height: 100px;
  width: 100px;
  display: inline-block;
  line-height: 100px;
  font-size: 40px;
  color: #fff;
  border-radius: 50%;
  margin-bottom: 20px;
}
.operator {
  font-size: 80px;
  color: var(--primary-color);

  text-align: center;
  line-height: normal;
}

.b-none {
  border: none;
}

.measure-container .subobj {
  flex: inherit;
  margin: inherit;
  gap: 30px;
}

.objectscal {
  display: flex;
  align-items: center;
}

.objectscal figure {
  margin: 0px;
  text-align: center;
  padding: 0px;
}
.meaning-counting .col-md-2:last-child input {
  display: none;
}
.colorvia .fa-times {
  color: white;
  background: radial-gradient(73.44% 73.44% at 23.05% 10.55%, #ffced2 0%, #eb7e87 100%);
  border-radius: 40px;
  padding: 6px 8px;
  position: absolute;
  top: -7px;
  right: -15px;
}
.colorvia .fa-check {
  color: white;
  background: radial-gradient(70.7% 70.7% at 10.55% 23.05%, #d9ffbb 0%, #abd888 100%);
  border-radius: 40px;
  padding: 6px 6px;
  position: absolute;
  top: -7px;
  right: -13px;
}
.objgame img {
  height: 100px;
  width: 100px;
}
.objgame {
  margin-top: 0px !important;
}
.meaning-counting {
  justify-content: center;
}

.meaning-counting .score-item input {
  width: 80px;
  outline: none;
  border-radius: 8px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #c4c4c4;
  text-transform: uppercase;
  text-align: center;
  height: 80px;
  border: 4px dashed #c4c4c4;
  font-size: 42px;
  font-family: 'sorabold';
}
/* .meaning-counting .score-item:nth-child(3) input {
  display: none;
}

*/
.meaning-counting .score-item {
  /* display: flex;
                                                  flex: 0 0 30%; */
  gap: 10px;
  align-items: flex-end;
  justify-content: center;
}

.meaning-counting .option-container h3 {
  flex: 0 0 100%;

  background-color: var(--primary-color);
  color: #fff;
}

.meaning-counting .option-container .row {
  justify-content: center;
  margin-top: 20px;
}

.meaning-counting h3 {
  box-shadow: 0px 22px 25.6438px 2px rgb(0 0 0 / 5%);
  height: 80px;
  width: 80px;
  background: #fff;
  line-height: 78px;
  font-size: 42px;
  text-align: center;
  text-transform: uppercase;
  /* flex: 0 0 40%; */
  border-radius: 7px;
}
.categories-text h5 {
  text-transform: capitalize;
}
.quiz_card_area {
  position: relative;
  margin-bottom: 30px;
}
.single_quiz_card {
  background-color: white;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  border-radius: 16px;
  transition: all 0.3s linear;
}

.quiz_card_content {
  display: flex;
  font-size: 18px;
}

.quiz_checkbox {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: pointer;
}
.single_quiz_card {
  padding: 14px 0px;
}
.quiz_checkbox:checked ~ .single_quiz_card {
  background: #e3f4f2;
  background: rgb(227, 244, 242);
}

.quiz_checkbox:checked ~ .child-data:checked {
  color: #61bfb5;
}

.quiz_checkbox:checked ~ .single_quiz_card .quiz_card_content .quiz_card_title {
  background: #e3f4f2;
  color: #ffffff;
}

.quiz_checkbox:checked:hover ~ .quiz_card_title {
  border: 1px solid #2575fc;
}

.start-container {
  margin-top: 88px;
  padding-bottom: 30px;
}

.start-container button:nth-child(2) {
  margin-left: 4px;
}

.error {
  background-color: rgba(255, 0, 0, 0.151);
  border: 1px solid rgba(255, 0, 0, 0.151);
  padding: 10px 10px;
}

.pagination-container figure {
  position: absolute;
  top: 50%;
  border-radius: 50%;
}

.pagination-container figure:nth-child(1) {
  left: 0px;
}

.pagination-container figure:nth-child(2) {
  right: 0px;
}

.hours-container h6 {
  border: 2px solid #6bc3ba;
  border-radius: 4px;
  padding: 6px 14px;
  margin-right: 10px;
  font-size: 14px;
  color: #41414180;
  margin-bottom: 0;
}
.communicationpage .slider-container figure.slick-disabled {
  opacity: 0.3;
}

/* .stopbbtn {
  position: absolute;
  bottom: -25%;
  left: 27%;
  padding: 9px 40px;

} */
.gameCatContainer .slick-track {
  margin-left: 0;
}

.droptarget {
  display: inline-block;
}

.droptarget.highlighted {
  transition: 1s all;
  background-color: rgb(12, 26, 24);
}

.droptarget.highlighted input {
  background-color: transparent !important;
}

.refresh-btn {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: right;
}

.refresh-btn img {
  width: 20px;
  height: 20px;
}

.ledger-container {
  margin: 0;
  display: flex;
  padding: 0px;
  gap: 10px;
  align-items: center;
}

.ledger-container li {
  list-style: none;
  font-size: 12px;
}

.ledger-container li span {
  height: 12px;
  width: 12px;
  margin-left: 2px;
  display: inline-block;
  border-radius: 10px;
}

.ledger-container li:nth-child(1) span {
  background-color: #afdc8f;
}

.ledger-container li:nth-child(2) span {
  background-color: #fbc3c6;
}

.ledger-container li:nth-child(3) span {
  background-color: #c4c4c4;
}
/* .option-main-container .slick-prev img {
   transform: rotate(180deg);
 } */
/* .three_words h5 {
  font-size: 84px;
  font-weight: 900;
}
.three_words {
  background: radial-gradient(
      98.07% 73.14% at 3.51% 19.05%,
      #97d5cd 8.51%,
      #54baaf 100%
    ),
    #d9d9d9;
}
.words_image {
  display: flex;
  padding: 6px 45px !important;
  gap: 20px;
}
.answer-data h5 {
  font-size: 96px;
  padding: 30px 38px;
}

.wordsthree {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-around !important;
}
.words_image4 {
  display: flex;
  justify-content: space-evenly;
  margin-top: 18px;
}
.words {
  box-shadow: 0px 22px 25.6438px 2px rgb(0 0 0 / 5%);
  border-radius: 10px;
  width: 10%;
  text-align: center;
  padding: 26px 24px;
}
.words h5 {
  font-size: 40px;
} */
.learning {
  position: absolute;
  top: 145px;
  gap: 11px;
  display: flex;
  right: 151px !important;
}

.Two-personalised {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 40px;
}

.left-personalised {
  display: flex;
}

.right-personalised {
  display: flex;
  align-items: center;
}

.intrest-points p {
  margin: 0;
}
.intrest-points span {
  color: #54baaf;
  font-weight: 600;
}
.intrest-score span {
  color: var(--secondary-color);
}
.intrest-score p {
  flex: 1;
}
.intrest-score {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
}
.intrest-score span {
  color: var(--primary-color);
}
.studentprofile .module-container1 {
  display: flex;
  margin-left: 32px;
}
.intrest-score p {
  color: var(--secondary-color) !important;
  margin: 0;
  flex: 1;
  text-align: left;
}
.overall-categories {
  text-align: center;
  padding: 10px 7px;
}
.Child-information {
  padding-left: 10px;
  margin-top: 10px;
}
.Child-score {
  margin-top: 20px;
}

.Child-score-data {
  background: white;
  /* margin-top: 30px; */
  width: 100%;
  font-size: 20px;
  border-radius: 8px;
  padding: 24px 10px;
  text-align: center;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
.Child-image {
  text-align: center;
}
.Child-score-category p {
  text-align: center !important;
  color: #54baaf !important;
  font-size: 15px !important;
  font-weight: 800;
}
.Child-information h4 {
  text-align: center;
}
/* .Child-information {
  display: flex;
  flex-direction: column;
} */

.Child-information span {
  margin: 0;
  color: var(--secondary-color);
  font-size: 14px;
}
.Child-information p {
  margin: 0;
  color: var(--secondary-color);
}
.Headset-msg p {
  color: red;
}
.Child-score {
  text-align: center;
}
/* .data-table-extensions-action {
  display: none;
} */
.userInfo-container {
  padding: 0;
  display: flex;
  align-items: center;
}
.userInfo-container li {
  list-style: none;
  flex: 1;
  color: var(--primary-color);
}
.englishDataName {
  height: 200px;
  width: 200px;
  object-fit: cover;
}
.css-1fdsijx-ValueContainer {
  width: 100%;
}
.std-profile2 {
  margin-top: 18px;
}
.option-container button {
  padding: 8px 30px;
  margin-left: auto;
}
.img-item {
  height: 80px !important;
  width: 80px !important;
  margin: 10px;
}
.result-table .video-react {
  width: 200px !important;
}
.personalised li {
  background-color: #fff;
  color: #000;
  font-size: 14px;
  border: 1px solid;
}
.video-player {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.video-content {
  /* position: absolute; */
  /* text-align: center; */
  /* top: 50%; */
  /* left: 50%; */
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* .css-1n9rq6o-Box {
  display: none !important;
} */
.css-i86q95 {
  background: var(--primary-gradient) !important;
  position: absolute;
  right: 0;
  top: -68px;
}
.css-1j1ljdj {
  display: none !important;
}
.css-xwxfpg-Box {
  display: none !important;
}
.typing-game .preview-txt-container {
  border: 4px solid #c4c4c4;
  text-align: center;
  height: 100%;
  margin: auto;
  display: flex;
  font-size: 22px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.typing-game .preview-txt-container-word {
  border: 4px solid #c4c4c4;
  text-align: center;
  margin: auto;
  /* display: flex; */
  height: 100%;
  font-size: 22px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.question-no {
  background: var(--primary-color);
  color: #fff;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 10px 4px;
  margin: 0;
  line-height: 22px;
}
.typing-game {
  margin-top: 40px !important;
  margin-bottom: 40px;
  justify-content: center;
}
.typing-game textarea {
  text-align: center;
  padding: 35px 0px;
  height: 105px;

  font-size: 22px;
  /*  text-transform: uppercase;
*/
  font-weight: 700;
}
.typingtextArea textarea {
  height: 100%;
}
.radio-btn-container {
  margin-top: 10px;
}
.radio-btn-container .form-check {
  cursor: pointer;
}
.radio-btn-container .form-check-input:checked {
  background-color: var(--primary-color);
  border: none;
}
.video-react {
  width: 400px !important;
}
.ques-arrow-container {
  display: flex;
}
.categories-score .intrest-score p {
  margin: 0;
}
.std-profile {
  text-align: center;
}
.phrase-image img {
  width: 100%;
  height: 300px;
  object-fit: contain;
  border: 10px solid #c4c4c4;
}
.image-personalised img {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 50%;
}
/* .css-1n9rq6o-Box {
  display: none !important;
} */
.css-1n9rq6o-Box {
  width: 107px;
  z-index: 9999999;

  background: var(--primary-gradient);
}
.vocational-para p {
  margin: 0px;
  white-space: unset;
  max-height: 183px;
  font-size: 13px !important;
  overflow-x: unset !important;
  overflow: scroll;
  text-transform: none;
  font-size: 10px;
  padding: 7px 5px;
}

.paraans p {
  margin: 0px;
  white-space: unset;
  max-height: 183px;
  word-break: break-all;
  /* letter-spacing: 3px; */
  text-transform: none;
  font-size: 13px !important;
  overflow-x: unset !important;
  /* letter-spacing: 8px; */
  overflow: scroll;
  width: 104%;
  color: #414141 !important;
  opacity: 0.9;
  font-size: 10px;
  padding: 7px 5px;
}
.percentage-para {
  color: var(--primary-color);
  border: 2px solid #414141;
  border-radius: 25px;
  background: white;
  position: absolute;
  right: 13px;
  top: 10px;
  font-size: 15px;
  line-height: 31px;
  border-top: 2px solid #414141 !important;
}
.percentage-para p {
  margin: 3px;
}

.range {
  position: absolute;
  right: 36%;
  top: 4%;
}
.range input {
  width: 154px;
  margin-top: 12px;
}
.range span {
  color: var(--primary-color);
}
.range p {
  font-family: 'sorabold';
  color: #414141;
  opacity: 0.7;
  margin: 0px;
  font-size: 20px;
}
.matching-per {
  position: absolute;
  border: 3px solid #68c2b8;
  padding: 5px;
  font-size: 10px;
  height: 50px;
  width: 50px;
  line-height: 35px;
  color: #68c2b8;
  top: -80px;
  right: 16px;
  text-align: center;
  border-radius: 50%;
}
.activity {
  position: relative;
  background-color: #272727;
  color: white;
  opacity: 1;
  height: 80px;
  width: 80px !important;
  border: none;
  text-align: center;
  margin: 12px;
  padding: 8px 22px !important;
  border-radius: 50%;
  transition: all 0.2s;
}
.audioContainer canvas {
  display: none;
}
.stop-btn {
  background-color: #ff3466 !important;
}
.pulse-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ff3466;
  border-radius: 100%;
  opacity: 0.5;
  top: 0;
  left: 0;
  animation: pulse 1s ease-out infinite;
}
/* :hover {
     background-color: "#ff3466";
     opacity: 0.9;
   } */
@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }
  50% {
    opacity: 0.3;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}
.sidenav {
  background: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
}

.hame-menu {
  display: none;
  padding-top: 30px;
  padding: 25px 12px;
}
.hame-menu svg {
  height: 24px;
  padding: 5px;

  cursor: pointer;
  width: 24px;
}
.hame-menu svg:hover {
  background-color: var(--primary-color);
  border-radius: 50%;
  fill: #fff;
}

.excelgame-container .content-container p {
  color: #000;
  line-height: 50px;
}
.excelgame-container thead {
  background-image: var(--primary-gradient);
}
.excelgame-container table {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgb(99 99 99 / 20%) 0;
}
.excelgame-container thead th {
  color: #fff;
  text-transform: capitalize;
  padding: 13px;
}
.excelgame-container .table-striped > tbody > tr {
  border: none;
}
.excelgame-container .table-striped > tbody > tr:nth-of-type(even) > * {
  background: #5abdb261;
  border: none;
  padding: 7px;
}
.excelgame-container .table-striped > tbody > tr:nth-of-type(odd) > * {
  background: #fff;
  border: none;
  box-shadow: unset;
  padding: 7px;
}
.excelgame-container .table > :not(:first-child) {
  border: none;
}
/*.excelgame-container .table-striped>tbody>tr:nth-of-type(odd)>* input{border-color:#fff;color:#fff;}
*/ /*.excelgame-container  tbody{border:none;}
*/
.excelgame-container thead tr {
  border: none;
}
.matching-container {
  display: flex;
  margin-top: 30px;
}
.tbody-container input {
  font-size: 13px;
  padding: 0;
  border: none;
  padding-bottom: 0;
}
.matching-container h4 {
  margin-bottom: 14px;
}
.matching-container .matching-item .droptarget.highlighted {
  opacity: 0.5;
  background-color: transparent;
}
.matching-container .matching-item img {
  cursor: pointer;
  width: 100%;
  height: 150px;
  object-fit: contain;
  border: 12px solid #c4c4c4;
  margin-bottom: 10px;
}
.css-pv2stz-Box {
  position: absolute;
  top: -128px;
  right: 25px;
}
.outline-btn:hover {
  background-color: #00beb4;
  color: #fff;
  transition: 1s all;
}
.outline-btn {
  width: inherit;
  margin-right: auto;
  background-color: transparent;
  border: 2px solid #00beb4;
  color: var(--primary-color);
  border-radius: 6px;
  cursor: pointer;
  padding: 4px 20px;
  margin-top: 10px;
}
.excelgame-container {
  margin-bottom: 20px;
}
.excelgame-container h4 {
  font-size: 20px;
  margin-bottom: 10px;
}
.excelgame-container h5 {
  font-size: 15px;
  /* margin-bottom: 10px; */
  text-transform: capitalize;
}
.play-type-container p {
  flex: 0 0 50%;
}
.play-type-container .form-container {
  flex: 0 0 30%;
}

.excelgame-container p {
  font-size: 12px;
  /* margin-bottom: 10px; */
  text-transform: capitalize;
}
.subobj {
  display: flex;
  align-items: center;
}
.hide {
  width: 100% !important;
}
@media only screen and (max-width: 768px) {
  .animate .content {
    padding-top: 30px;
  }
  .hame-menu {
    display: block;
    position: fixed;
    padding: 12px 10px;
    width: 100%;
    background: #fff;
    box-shadow: rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 2px 6px 2px;
    z-index: 999;
    left: 0;
    background: #fff;
  }
  .hame-menu button {
    background-color: transparent;
    border: none;
  }
}
.card--skeleton h6 {
  background-color: #eee;
}
.card--skeleton p {
  background-color: #eee;
}
.card--skeleton span {
  visibility: hidden;
}
.card--skeleton img {
  visibility: hidden;
}
.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none;
}
.card--skeleton .btn-container {
  background: #eee !important;
}
.card--skeleton .btn-container button {
  background: #eee !important;
  box-shadow: unset;
}
.card--skeleton .image {
  background-color: #eee;
}
.card--skeleton::before {
  content: '';
  position: absolute;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.9), transparent);
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
  -webkit-animation: loading 1s infinite;
  animation: loading 1s infinite;
}
.card--skeleton .img-cont {
  background: #eee;
}
.allreport .data-table-extensions-filter .icon {
  margin: 0;
}
.allreport .report-tab .nav-item {
  flex: 0 0;
}
.allreport .data-table-extensions {
  padding: 0;
}
.data-table-extensions-filter {
  display: flex;
  background: #f7ffff;
  align-items: center;
  /* width: 100%; */
  border-radius: 4px;
  border: 1px solid;
  padding: 0px 10px;
  border-radius: 4px;
}
.card--skeleton .user-avatar-cont {
  background: #eee;
}
.card--skeleton .user-name span,
.card--skeleton .user-profession span {
  background: #eee;
  color: #eee;
  border-radius: 5px;
}

@-webkit-keyframes loading {
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }
  100% {
    transform: skewX(-10deg) translateX(200%);
  }
}
.nav-link {
  white-space: nowrap;
}
@keyframes loading {
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }
  100% {
    transform: skewX(-10deg) translateX(200%);
  }
}
.brainAnalysis-container {
  max-width: 95%;
  margin: 50px auto;
}
.brain-container .content {
  padding: 0;
  margin: 0;
}

/* .brain-circle {
  height: 500px;
  width: 500px;
  border: 2px solid #c4c4c4;
  border-radius: 50%;
  position: relative;
} */

.brain-circle::before {
  content: '';
  height: 75px;
  width: 75px;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, -50%);
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: #dff5f3;
  z-index: -1;
}

.slider-item ul {
  padding: 0;
  margin-top: 25px;
}
.slider-item ul li {
  list-style: none;
  margin-bottom: 20px;
}
.slider-item ul li p {
  margin: 0;
  flex: 0 0 118px;
  font-weight: 600;
}

.slider-item ul li span {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 12px;
  padding: 1px 6px;
  border-radius: 8px;
  font-weight: 800;
}

.ear {
  position: absolute;
  height: 64px;
  width: 20px;
  z-index: -1;
  background: #c4c4c4;
  top: 38%;
}
.ear.left {
  left: -16px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
}
.ear.right {
  right: -16px;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
}
.brain-coordinates li {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 12px;
  position: absolute;
  gap: 10px;
  margin-bottom: 10px;
}
.brain-coordinates {
  padding: 0;
}
.graph-loader-container {
  position: absolute;
  top: 50%;
  text-align: center;
  left: 50%;
  transform: translate(-50%, -50%);
}
.graph-loader-container p {
  color: var(--secondary-color);
  font-size: 22px !important;
  font-weight: 700;
}
.graph-loader {
  height: 200px;
  width: 200px;
}

.graph-loader__element {
  stroke-width: 8;
  fill: transparent;
  animation: 5s ease-in-out infinite;
}

.graph-loader__element--bar {
  transform-origin: 50px 75px;
}
.graph-loader__element--bar:nth-child(1) {
  stroke: rgba(95, 192, 221, 0.2);
  animation-name: graph-loader__bar--1;
  animation-delay: -90ms;
}
.graph-loader__element--bar:nth-child(2) {
  stroke: rgba(95, 192, 221, 0.4);
  animation-name: graph-loader__bar--2;
  animation-delay: -30ms;
}
.graph-loader__element--bar:nth-child(3) {
  stroke: rgba(95, 192, 221, 0.7);
  animation-name: graph-loader__bar--3;
  animation-delay: 30ms;
}
.graph-loader__element--bar:nth-child(4) {
  stroke: #5fc0dd;
  animation-name: graph-loader__bar--4;
  animation-delay: 90ms;
}

@keyframes graph-loader__bar--1 {
  0% {
    transform: scaleY(0);
  }
  4% {
    transform: scaleY(1);
  }
  12.66% {
    transform: scaleY(1);
  }
  20.66% {
    transform: scaleY(4.5);
  }
  29.33% {
    transform: scaleY(4.5);
  }
  37.33% {
    transform: scaleY(2.5);
  }
  46% {
    transform: scaleY(2.5);
  }
  50% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(0);
  }
}
@keyframes graph-loader__bar--2 {
  0% {
    transform: scaleY(0);
  }
  4% {
    transform: scaleY(1);
  }
  12.66% {
    transform: scaleY(1);
  }
  20.66% {
    transform: scaleY(0.6);
  }
  29.33% {
    transform: scaleY(0.6);
  }
  37.33% {
    transform: scaleY(2.2);
  }
  46% {
    transform: scaleY(2.2);
  }
  50% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(0);
  }
}
@keyframes graph-loader__bar--3 {
  0% {
    transform: scaleY(0);
  }
  4% {
    transform: scaleY(1);
  }
  12.66% {
    transform: scaleY(1);
  }
  20.66% {
    transform: scaleY(1.3);
  }
  29.33% {
    transform: scaleY(1.3);
  }
  37.33% {
    transform: scaleY(0.8);
  }
  46% {
    transform: scaleY(0.8);
  }
  50% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(0);
  }
}
@keyframes graph-loader__bar--4 {
  0% {
    transform: scaleY(0);
  }
  4% {
    transform: scaleY(1);
  }
  12.66% {
    transform: scaleY(1);
  }
  20.66% {
    transform: scaleY(0.5);
  }
  29.33% {
    transform: scaleY(0.5);
  }
  37.33% {
    transform: scaleY(0.2);
  }
  46% {
    transform: scaleY(0.2);
  }
  50% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(0);
  }
}
.graph-loader__element--pie:nth-child(1) {
  stroke: rgba(95, 192, 221, 0.2);
  animation-name: graph-loader__pie--1;
}
.graph-loader__element--pie:nth-child(2) {
  stroke: rgba(95, 192, 221, 0.4);
  animation-name: graph-loader__pie--2;
}
.graph-loader__element--pie:nth-child(3) {
  stroke: rgba(95, 192, 221, 0.7);
  animation-name: graph-loader__pie--3;
}
.graph-loader__element--pie:nth-child(4) {
  stroke: #5fc0dd;
  animation-name: graph-loader__pie--4;
}

@keyframes graph-loader__pie--1 {
  0% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 0;
  }
  50% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 0;
  }
  54% {
    opacity: 1;
    stroke-dasharray: 20 140;
    stroke-dashoffset: 0;
  }
  62.66% {
    opacity: 1;
    stroke-dasharray: 20 140;
    stroke-dashoffset: 0;
  }
  70.66% {
    opacity: 1;
    stroke-dasharray: 60 100;
    stroke-dashoffset: 0;
  }
  79.33% {
    opacity: 1;
    stroke-dasharray: 60 100;
    stroke-dashoffset: 0;
  }
  87.33% {
    opacity: 1;
    stroke-dasharray: 40 120;
    stroke-dashoffset: 0;
  }
  96% {
    opacity: 1;
    stroke-dasharray: 40 120;
    stroke-dashoffset: 0;
  }
  100% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 0;
  }
}
@keyframes graph-loader__pie--2 {
  0% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 135;
  }
  50% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 135;
  }
  54% {
    opacity: 1;
    stroke-dasharray: 30 130;
    stroke-dashoffset: 135;
  }
  62.66% {
    opacity: 1;
    stroke-dasharray: 30 130;
    stroke-dashoffset: 135;
  }
  70.66% {
    opacity: 1;
    stroke-dasharray: 20 140;
    stroke-dashoffset: 95;
  }
  79.33% {
    opacity: 1;
    stroke-dasharray: 20 140;
    stroke-dashoffset: 95;
  }
  87.33% {
    opacity: 1;
    stroke-dasharray: 10 150;
    stroke-dashoffset: 115;
  }
  96% {
    opacity: 1;
    stroke-dasharray: 10 150;
    stroke-dashoffset: 115;
  }
  100% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 135;
  }
}
@keyframes graph-loader__pie--3 {
  0% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 260;
  }
  50% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 260;
  }
  54% {
    opacity: 1;
    stroke-dasharray: 35 125;
    stroke-dashoffset: 260;
  }
  62.66% {
    opacity: 1;
    stroke-dasharray: 35 125;
    stroke-dashoffset: 260;
  }
  70.66% {
    opacity: 1;
    stroke-dasharray: 40 110;
    stroke-dashoffset: 210;
  }
  79.33% {
    opacity: 1;
    stroke-dasharray: 40 110;
    stroke-dashoffset: 210;
  }
  87.33% {
    opacity: 1;
    stroke-dasharray: 55 105;
    stroke-dashoffset: 260;
  }
  96% {
    opacity: 1;
    stroke-dasharray: 55 105;
    stroke-dashoffset: 260;
  }
  100% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 260;
  }
}
@keyframes graph-loader__pie--4 {
  0% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 380;
  }
  50% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 380;
  }
  54% {
    opacity: 1;
    stroke-dasharray: 50 110;
    stroke-dashoffset: 380;
  }
  62.66% {
    opacity: 1;
    stroke-dasharray: 50 110;
    stroke-dashoffset: 380;
  }
  70.66% {
    opacity: 1;
    stroke-dasharray: 15 145;
    stroke-dashoffset: 345;
  }
  79.33% {
    opacity: 1;
    stroke-dasharray: 15 145;
    stroke-dashoffset: 345;
  }
  87.33% {
    opacity: 1;
    stroke-dasharray: 30 130;
    stroke-dashoffset: 360;
  }
  96% {
    opacity: 1;
    stroke-dasharray: 30 130;
    stroke-dashoffset: 360;
  }
  100% {
    opacity: 0;
    stroke-dasharray: 0 160;
    stroke-dashoffset: 380;
  }
}

.brain-coordinates .dots {
  height: 20px;
  width: 20px;
  background: transparent;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  line-height: 64px;
  margin: 0;
  padding: 3px;
}
.innerdot {
  height: 10px;
  width: 10px;
  display: block;
  /* line-height: 42px; */
  /* text-align: center; */
  margin: auto;
  border-radius: 50%;
}
.innerdot.active {
  background-color: var(--primary-color);
}
.admin-container p {
  color: var(--secondary-color);
  font-family: 'soraregular';
  margin: 0px;
  font-size: 12px;
}

.blob-container .blob {
  position: absolute;
  text-align: center;
  justify-content: center;
}
.blob-container .blob:nth-child(1) {
  background: #dfdbe4;
  left: 12px;
  top: 0;
  left: 36px;
  height: 135px;
  width: 77px;
  z-index: -1;
  border-radius: 60px;
  transform: rotate(47deg);
}
.blob-container .blob:nth-child(2) {
  background: #dfdbe4;
  right: 12px;
  z-index: -1;
  right: 36px;
  height: 135px;
  width: 77px;
  top: 0;
  border-radius: 60px;
  transform: rotate(311deg);
}
.blob-container .blob:nth-child(3) {
  background: #fbcacb;
  left: 6px;
  z-index: -1;
  height: 135px;
  width: 52px;
  bottom: 33px;
  border-radius: 126px;
  transform: rotate(338deg);
}
.blob-container .blob:nth-child(4) {
  background: #fbcacb;
  right: 12px;
  z-index: -1;
  right: 5px;
  bottom: 45px;
  height: 135px;
  width: 57px;
  border-radius: 60px;
  transform: rotate(17deg);
}
.blob-container .blob:nth-child(5) {
  background: #c1f0dc;
  right: 33%;
  z-index: -1;
  border-radius: 35px;
  height: 60px;
  width: 95px;
  bottom: -9px;
}
.blob-container .blob:nth-child(6) {
  background: #fff3ce;
  right: 30%;
  z-index: -1;
  border-radius: 27px;
  height: 76px;
  width: 115px;
  bottom: 66px;
}

.blob-container .blob:nth-child(7) {
  background: #fff3ce;
  left: 19%;
  bottom: 1.5%;
  z-index: -1;
  border-radius: 15px;
  height: 45px;
  width: 30px;
  transform: rotate(-50deg);
}
.blob-container .blob:nth-child(8) {
  background: #fff3ce;
  right: 19%;
  bottom: 1.5%;
  z-index: -1;
  border-radius: 15px;
  height: 45px;
  width: 30px;
  transform: rotate(50deg);
}
#amountInput {
  border: 2px solid var(--primary-color);
  background: #fff;
  padding: 20px 10px;
  border-radius: 6px;
}
/* .spin-counter {
  display: flex;
  align-items: center;
  width: 138px;
  background: #e0dbe4;
} */
.spin-counter button {
  background-color: var(--primary-color);
  border: 0;
  padding: 10px 10px;
  font-size: 15px;
  color: #fff;

  width: inherit !important;
}
.threshold-count {
  gap: 10px;
}
.spin-counter input {
  display: flex;
  align-items: center;
  /* width: 120px; */
  background: #e0dbe4;
  color: #fff;
}
.threshold-counter p {
  margin: 0;
  margin-bottom: 4px;
  font-weight: 700;
}
.audio-controls,
.video-controls {
  margin-bottom: 20px;
}
.video-player .live-player {
  height: 300px;
}
.audio-player,
.video-player,
.recorded-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eefafe;
  padding: 10px 10px;
  border-radius: 20px;
}

.live-player {
  margin-bottom: 30px;
  margin: 0;
  padding: 20;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px; */
  background: #eefafe;
  /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
  border-radius: 1px;
}
.recorded-player video {
  height: 300px;
  width: 800px;
}
.video-content h3 {
  font-weight: 400;
  font-size: 20px;
}
.live-player h4 {
  margin: 0;
}
.live-player p {
  color: var(--secondary-color);
  margin-bottom: 7px;
}
.module-containr {
  position: relative;
}
.threshold-counter .btn {
  background: var(--primary-gradient);
  font-size: 12px;
  padding: 10px 10px;
  border-radius: 0;
  border: none;
  padding: 6px 10px;
  width: inherit !important;
  margin-top: 4px;
}
.questionImage img {
  min-width: inherit !important;
}

.module-container1 .pagination-container figure {
  top: 21%;
  height: 25px;
  width: 25px;
}

.module-container1 .pagination-container figure:nth-child(1) {
  left: -20px;
}
.module-container1 .pagination-container figure:nth-child(2) {
  right: -20px;
}
.module-container1 .pagination-container {
  margin: 0;
}
.module-containr {
  padding-bottom: 0;
}
.module-containr::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}
.module-containr::-webkit-scrollbar-track {
  display: none;
}
.module-containr::-webkit-scrollbar-thumb {
  display: none;
}
.std-dropdownContainer .module-containr {
  display: flex;
  align-items: center;
  width: 372px;
}
.pdf-content-container {
  padding: 0 45px !important;
}
.sidebar {
  position: fixed;
  left: 0px;
  opacity: 1;
  top: -100%;

  width: 248px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 700;
  opacity: 1;
  z-index: 999;
  background-color: #fff !important;
}
.std-dropdownContainer .dropdown-container {
  display: flex;
  /* flex-wrap: wrap; */
}
.std-dropdownContainer button.active {
  background: var(--primary-gradient);
  color: #fff;
}
.std-dropdownContainer button {
  list-style: none;
  border: 1px solid #00bdb2;
  color: #666666;
  background-color: #fff;
  padding: 4px;
  cursor: pointer;
  margin: 0px 4px;
  border-radius: 5px;
  font-family: soraregular;
  padding: 4px 10px;
  font-size: 13px;
  white-space: nowrap;
}
.reading-lvl {
  border: 7px solid;
  border-radius: 10px;
  padding: 30px 10px;
  font-weight: 900;
}
.hstack .stackItem {
  display: flex;
  align-items: center;
  flex: 1 1 100%;
  /* padding: 0px 14px; */
  justify-content: space-between;
  border: 1px solid var(--primary-color);
}
.hstack .stackItem p:last-child {
  text-align: center;
}
.hstack .stackItem p {
  margin: 0px;
  flex: 0 0 33.33%;
  border-right: 1px solid var(--primary-color);
  padding: 4px;
  font-size: 12px;
}
.hstack .stackItem span {
  padding: 4px;
  font-size: 12px;
}

.hstack {
  flex-wrap: wrap;
  margin-top: 10px;
}
.doughnut-container {
  height: 200px;
  margin: 0;
  width: 200px;
  margin: auto;
}
.live-player h5 {
  font-weight: 400;
  text-align: center;
  font-size: 15px;
}
/* .live-player  p{margin:0} */
.reading-lvl p {
  margin: 0;
}
.tabl-container {
  display: flex;
}
.tabl-container span {
  margin-right: 10px;
  font-size: 20px;
  display: inline-block;
  height: 30px;
  width: 30px;
  background: #00bfb4;

  color: #fff;
  text-align: center;
  line-height: 1.7;
  border-radius: 50%;
}
.dashboard-container.container-fluid {
  padding: 0;
}
.teach-with,
.brain-strength,
.slider,
.label {
  display: flex;
}

.slider-box,
.label-box {
  margin: 10px 5px;
}

.slider,
.label {
  align-items: center;
  height: 50px;
}

.label {
  justify-content: end;
}

.slider-box .slider span {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  font-size: 12px;
  padding: 1px 6px;
  border-radius: 8px;
  font-weight: 800;
  margin-left: 10px;
}

.label-box .label span {
  display: inline-block;
  margin-right: 5px;
  border: none;
  border-radius: 100%;
  height: 20px;
  width: 20px;
}

.label-box .label p {
  margin: 0;
  font-weight: 600;
}
.spin-counter > div {
  display: flex;
  align-items: center;
  margin-right: 5px;
  width: 138px;
  background: #e0dbe4;
}
.spin-counter button {
  background-color: var(--primary-color);
  border: 0;
  padding: 10px 10px;
  font-size: 15px;
  color: #fff;
  width: inherit !important;
}

.spin-counter input {
  color: #fff;
}
.spin-counter p {
  margin: 5px 0;
  font-weight: 700;
}
.custom-btn {
  background-color: var(--primary-color);
  border: 0;
  padding: 10px 10px;
  font-size: 15px;
  color: #fff;
  margin-right: 5px;
}
.timer-popup {
  position: absolute;
  right: 0;
  top: 208px;
  padding: 9px 11px;
  border-bottom-left-radius: 10px;
  border-radius: 0;
  background: var(--primary-gradient);
  color: #fff;
  border-top-left-radius: 10px;
  z-index: 9999;
  border-bottom-left-radius: 10px;
}
@media only screen and (max-width: 768px) {
  .option-main-container .slick-prev {
    left: 48px;
  }
  .studentprofile {
    flex-wrap: wrap;
  }
  .studentprofile .module-container1 {
    margin-left: 0;
  }
}
@media only screen and (max-width: 600px) {
  .hours-container h6 {
    display: none;
  }
  .chart-container canvas {
    height: 56px !important;
    width: 56px !important;
    margin: auto;
  }
  .chart-container h5 {
    font-size: 12px;
  }
  .pagination-container figure {
    display: none;
  }
  .percent-container h5 {
    font-size: 12px;
  }
  header .navbar {
    padding: 26px 11px;
  }
  .std-dropdownContainer .dropdown-container {
    flex-wrap: wrap;
  }

  .performance-heading button {
    font-size: 10px;
    padding: 10px 10px;
  }

  .o-1 {
    order: -1;
  }
  .language-row {
    margin-top: 20px;
  }
  .language-row .Card1 {
    margin-bottom: 20px;
  }
  .navbar-toggler {
    display: none;
  }
  .select-role-container .select-role-item {
    padding: 18px 12px;
    margin: 0;
  }
  .select-role-container .select-role-item svg {
    height: 95px;
    width: 80px;
  }
  .dashboard-heading .activity-container {
    margin-left: auto;
  }
  .data-table-extensions-filter {
    width: 100%;
  }
  .data-table-extensions {
    padding-left: 0;
    padding: 0.7rem 0rem;
  }
  .data-table-extensions {
    padding: 0;
    margin-top: 10px;
  }
  .week-report {
    position: static;
    flex-wrap: wrap;
  }
  .dashboard-heading .content-container {
    order: 2;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .sidenav {
    background-color: transparent;
    box-shadow: unset;
  }

  .animate .content {
    width: 100%;
    margin: 0;
  }
  .openMobMenu {
    position: absolute;
    margin: 0;
    background: var(--primary-gradient);
    top: 30px;
    left: 14px;
    z-index: 999;
    border-radius: 6px;
  }
  .openMobMenu svg {
    height: 35px;
    width: 35px;
    fill: #fff;
  }
  .profile-image .content-container {
    margin: 0;
  }
  .std-dropdownContainer button {
    font-size: 12px;
  }

  .dashboard-cards h4 {
    font-size: 14px;
  }
}

.cat-input .radio-container button.active {
  background: var(--primary-gradient);
  color: #fff;
}

.cat-input .radio-container button {
  flex-grow: 1;
  list-style: none;
  height: 45px;
  min-width: 80px !important;
  border: 3px solid var(--primary-color);
  border-radius: 27px;
  background-color: #fff;
  cursor: pointer;
  margin: 4px;
  font-family: soraregular;
  padding: 4px 10px;
  font-size: 13px;
  white-space: nowrap;
}

.counter-container button {
  border: 3px solid var(--primary-color);
  color: black;
  background-color: #fff;
  cursor: pointer;
  font-size: 18px;
  border-radius: 100%;
  height: 40px;
  width: 40px !important;
}

.counter-container span {
  font-size: 18px;
  color: #000;
  margin: 0 10px;
  user-select: none;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background-image: var(--primary-gradient);
  border-color: #dee2e6 #dee2e6 #fff;
  color: #fff;
}
.form-card-design .cat-input .radio-container button {
  padding: 4px 10px;
  height: inherit;
  border-radius: 6px;
  font-size: 12px;
  border: 2px solid #00bdb2;
}
.form-card-design .counter-container button {
  border-radius: 10px;
  border-width: 2px;
  height: inherit;
  width: inherit !important;
  font-size: 16px;
  padding: 1px 8px;
  line-height: normal;
}
.form-card-design .counter-container span {
  font-size: 15px;
}
/* .form-card-design .dzu-dropzone {
  height: 60px;
} */
.card-container {
  font-family: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
    'Helvetica Neue', sans-serif;
  width: 100%;
  display: flex;
}

.card-ig {
  min-width: 350px;
  min-height: 450px;
  max-width: 350px;
  max-height: 450px;
  background-color: #ffffff;
  margin: 5% auto;
  border-radius: 5px;
}

/* Top Card styling */

.top {
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 5px 15px;
}

.body-icons {
  display: flex;
  justify-content: space-between;
}

.info img {
  width: 35px;
  height: 35px;
  padding: 2px;
  background-color: #ffff;
  border-radius: 50%;
}

.info {
  display: flex;
}

.user-name {
  margin: 0px 10px;
}

.user-name p {
  margin: 0;
}

.user-name .name {
  font-size: 13px;
  font-weight: 600;
  color: #000;
}

.user-name .id {
  font-size: 10px;
  color: grey;
}

/* body Styling */
.body,
.bottom-section {
  padding: 5px 15px;
}
.body img {
  height: 280px;
  width: 100%;
  border-radius: 5px;
  object-fit: cover;
}

.body-icons {
  font-size: 20px;
  padding: 5px 0px;
}

/* Bottom Styling */

.bottom-section {
  padding-top: 0;
  padding-bottom: 15px;
}

.bottom-section > span {
  font-size: 10px;
  font-weight: 500;
}

.caption-container p {
  display: inline;
  margin-right: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
.caption-container span {
  font-size: smaller;
  font-weight: 300;
}

.card-ig .input-ig {
  width: 100%;
  padding: 0;

  height: 20px;
  border-bottom: 2px solid #f3f3f3;
  font-size: 15px;
}

.file-label {
  width: 100%;
  height: 280px;
  border: 2px solid #f3f3f3;
  font-size: 56px;
  font-weight: 300;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
}

.file-label:hover {
  background-color: #f3f3f3;
}
.answerCheckbox label {
  font-size: 15px;
  color: #000;
}

.answerCheckbox .form-check-input:checked {
  background-color: var(--primary-color);
}
.stat-btn {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  margin-right: 24px;
  border-radius: 8px;
  padding: 15px 7px;
  font-size: 30px;
}
.stat-btn:hover {
  background: var(--primary-gradient);
  color: #fff;
}
.chips-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.chips-container > span {
  margin-bottom: 4px;
}

.chips-container .chips-ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 12px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.chips-container .chips-ul > li {
  padding: 6px 12px;
  color: #424242;
  background-color: #fafafa;
  border-radius: 16px;
  border: 2px solid #c9c9c9;
  cursor: pointer;
  font-family: soraregular;
  font-size: 16px;

  text-transform: capitalize;
  transition: all 0.2s ease;
}
.chips-container .chips-ul > li > svg {
  display: none;
}

.chips-ul > li.active > svg {
  display: block;
}
.chips-container .chips-ul > li:hover {
  border-color: var(--green-border-color);
  background-color: var(--green-highlight-color);
  color: var(--green-border-color);
}

.chips-ul > li.active {
  display: flex;
  align-items: center;
  gap: 6px;
  border-color: var(--green-border-color);
  background-color: var(--green-highlight-color);
  color: var(--green-border-color);
}

.typingtextArea input {
  background: #fff;
}

.green {
  color: green;
}

.red {
  background-color: rgba(255, 0, 0, 0.5);
}

.underline {
  border-bottom: 1px solid #000;
}

.word {
  font-size: 20px;
  margin: 2px;
}

.share {
  color: #38a1f3;
}

.slick-dots {
  bottom: 95%;
}

.rsc .rsc-ts-image {
  height: 80px;
  width: 80px;
}

.page1-icon-container {
  padding: 20px 20px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  background-color: #e9e9e9;
  margin: 45px 0px;
  align-items: center;
}
.page1-icon-container li {
  list-style: none;
  display: flex;
  align-items: center;
  flex: 0 0 33.33%;
  gap: 6px;
  padding: 12px;
}
.page1-icon-container li svg {
  height: 45px;
  width: 45px;
}
.page1-icon-container li h3 {
  margin: -0;
}
.page1-icon-container li p {
  margin: 0;
}
.header-main-container {
  display: flex;
  align-items: center;
  /* padding: 20px 72px; */
}
.header-main-container .header-content {
  flex: 0 0 50%;
}
.header-main-container figure {
  flex: 0 0 60%;
  text-align: center;
}
@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .main-container {
    padding-top: 0 !important;
  }
  .d-print-none {
    display: none !important;
  }

  .d-print {
    display: block !important;
  }

  .content {
    padding: 0 !important;
    margin: 0 !important;
  }
  .container-fluid {
    padding: 0 !important;
    margin: 0 !important;
  }
  .page-break {
    page-break-before: always;
  }

  /* Adjust container width for landscape printing */
  .landscape-print {
    width: 100% !important;
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Adjust column widths for landscape printing */
  .landscape-print .col-6 {
    width: 50% !important;
    float: left !important;
  }

  .landscape-print .col-3 {
    width: 25% !important;
  }
}

@page {
  size: a4 portrait;
  margin: 0mm;
}

@page landscape {
  size: a4 landscape;
  margin: 0;
}

@page portrait {
  size: a4 portrait;
  margin: 0;
}

/* 
.page1-container{
    background-image:url("../images/pdf-bgimg.png")
  } */
.otp-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
}
.otp-container input {
  border: 1px solid #ccc;
  margin-right: 10px;
  flex: 0 0 20%;
  border: 1px solid #ccc;
  margin-right: 18px !important;
  flex: 0 0 12%;
  border-radius: 4px;
  padding: 13px 10px !important;
  height: 52px;
  text-align: center;
  font-size: 34px !important;
  font-weight: 800;
  color: #00beb3;
}
.password-toggle-btn {
  position: absolute;
  right: 5px;
  top: 33px;
  border: navajowhite;
}

.allreport .sc-dmsloy {
  border-radius: 30px;
  overflow: hidden;
}

.allreport .sc-bcHOTY.fttDza.rdt_Table {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.allreport .gRDRZT {
  display: flex;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  background-color: #ffffff;
  min-height: 55px;
}
.allreport .gRDRZT {
  min-height: 55px;
  text-align: center;
}
.allreport .bPCTDf {
  color: #fff;
}
.allreport .gHNpyS {
  /* width: 70%; */
  padding: 15px 0;
}
.report-datatable {
  margin-top: 30px;
}
.downloadbtn-container {
  border-radius: 13px;
  margin: auto;
  text-align: center;
  border: #fff;
  background: #fff;
  padding: 30px 26px;
  border: 2px dashed #43cdc5;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
button.btn.btn-grey {
  background: #e7efef;
  margin-bottom: 18px;
}
.downloadbtn-container {
  margin: auto;
  text-align: center;
  width: 100%;
}
.downloadbtn-container img {
  height: 150px;
  width: 150px;
}
.quizes_report_table thead {
  border-bottom: 2px solid #c4c4c4;
  background: var(--primary-gradient);
  border-bottom: 2px solid #c4c4c4;
  font-size: 18px;
  background: #fbfcfc;
}
.report-tab .pdf-header {
  display: none;
}
.quizes_report_table th {
  font-size: 16px;
}
.quizes_report_table tr {
  margin-bottom: 8px;
}
.quizes_report_table td {
  padding: 7px 10px;
}
.quizes_report_table td {
  padding: 10px 12px !important;
}
.quizes_report_table p {
  margin: 0;
  margin-bottom: 10px;
  font-size: 12px;
}
.downloadbtn-container .common-btn {
  padding: 10px 33px !important;
  display: block;
  margin: auto;
  margin-top: 20px;
}
.allreport .data-table-extensions-filter {
  border-color: #00c0b6;
  justify-content: center;
}
.allreport .jINVri {
  text-align: center;
  justify-content: center;
}

.allreport .zdGQi {
  border-top: navajowhite;
  margin-top: 21px;
  border-radius: 12px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* width: 70%; */
}
.allreport [data-column-id='4'] [data-tag='allowRowEvents'] {
  background-color: #26c2b942;
  padding: 5px 23px;
  border-radius: 5px;
  font-weight: 800;
  color: #00bcb2;
}
.allreport [data-column-id='5'] [data-tag='allowRowEvents'] {
  background-color: #26c2b942;
  padding: 5px 23px;
  border-radius: 5px;
  font-weight: 800;
  color: #00bcb2;
}
.report-datatable {
  margin-top: 30px;
}
.allreport .pdf-header {
  display: none;
}
.footer-container {
  display: none !important;
}
.allreport .icons-container {
  display: none;
}
.week-report {
  top: 145px;
  gap: 11px;
  justify-content: center;
  display: flex;
  /* right: 25px; */
  margin-bottom: 32px;
}
.score_type_container .input-container {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.module-filter {
  width: 100%;
}
.module-filter .studentprofile {
  width: 100%;
}
.module-filter .dropdown-image {
  display: none;
}
.icons-container {
  display: none;
}
@media print {
  .report-tab .pdf-header {
    display: flex;
  }
  .common-btn {
    display: none;
  }
  .icons-container {
    display: block;
  }

  .main-spacing-container {
    margin: 0 50px !important;
  }
  .downloadbtn-container {
    display: none;
  }
  .dashboard-heading {
    display: none;
  }
  .data-table-extensions {
    display: none;
  }
  .downloadbtn-container {
    display: none;
  }
  .rdt_Pagination {
    display: none !important;
  }
  .allreport .pdf-header {
    display: block;
  }
  .allreport .pdf-header {
    display: flex;
  }
  .footer-container {
    display: flex !important;
    /* position: fixed; */
    bottom: 0;
    width: 100%;
  }
  .allreport .icons-container {
    display: block;
  }
}

.float-widget {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 100;
  display: flex; /* Add flex to align children vertically */
  align-items: flex-end; /* Align children to the bottom */
}

.floaty {
  position: fixed;
  width: 54px;
  height: 54px;
  bottom: 40px;
  right: 76px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 99;
  display: inline-block;
}
/* Adjust the percentage of margin top to fit your page */
.whatsappFloat {
  height: 100%;
  width: 100%;
  fill: #fff;
  padding: 0px 4px;
}

.whatsappFloat svg {
  height: 100%;
  width: 100%;
  fill: #fff;
  padding: 0px 4px;
}
