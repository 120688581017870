.services__card {
  position: relative;
  color: #050505 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  border: 3px solid #f6f6f6;
  border-radius: 8px;
  /* flex: 0 0 calc(25% - 12px); */
  height: 100%;
  cursor: pointer;
}

.services__card:hover {
  border: 3px solid #e8e8e8;
}

.services__card.active {
  border: 3px solid var(--main-color);
}

.services__card.active::after {
  content: '\2713';
  font-size: 24px;
  background-color: var(--main-color);
  color: white;
  border-radius: 12ch;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0%;
  top: 0%;
  margin: 6px;
  z-index: 99;
}
