.body-part {
  position: absolute;
}

.body-left-1 {
  top: 2%;
  left: 15%;
}
.body-left-1::after {
  content: '';
  display: block;
  position: absolute;
  left: 104%;
  top: 50%;
  background-color: #969696;
  border-radius: 20rem;
  height: 3px;
  width: 200%;
}

.body-left-2 {
  top: 15%;
  left: -2%;
}
.body-left-2::after {
  content: '';
  display: block;
  position: absolute;
  left: 104%;
  top: 50%;
  background-color: #969696;
  border-radius: 20rem;
  height: 3px;
  width: 260%;
}

.body-left-3 {
  top: 29%;
  left: 14%;
}
.body-left-3::after {
  content: '';
  display: block;
  position: absolute;
  left: 104%;
  top: 50%;
  background-color: #969696;
  border-radius: 20rem;
  height: 3px;
  width: 250%;
}

.body-left-4 {
  top: 50%;
  left: 20%;
}
.body-left-4::after {
  content: '';
  display: block;
  position: absolute;
  left: 66%;
  top: -60%;
  background-color: #969696;
  border-radius: 20rem;
  height: 3px;
  width: 247%;
  transform: rotate(-37deg);
}

.body-left-5 {
  top: 77%;
  left: 12%;
}
.body-left-5::after {
  content: '';
  display: block;
  position: absolute;
  left: 104%;
  top: 50%;
  background-color: #969696;
  border-radius: 20rem;
  height: 3px;
  width: 175%;
}

.body-right-1 {
  top: 4%;
  right: 12%;
}
.body-right-1::before {
  content: '';
  display: block;
  position: absolute;
  right: 91%;
  top: 114%;
  background-color: #969696;
  border-radius: 20rem;
  height: 3px;
  width: 301%;
  transform: rotate(338deg);
}

.body-right-2 {
  top: 25%;
  right: 15%;
}
.body-right-2::before {
  content: '';
  display: block;
  position: absolute;
  right: 104%;
  top: 50%;
  background-color: #969696;
  border-radius: 20rem;
  height: 3px;
  width: 190%;
}

.body-right-3 {
  top: 40%;
  right: 0;
}
.body-right-3::before {
  content: '';
  display: block;
  position: absolute;
  right: 100%;
  top: 22%;
  background-color: #969696;
  border-radius: 20rem;
  height: 3px;
  width: 315%;
  transform: rotate(10deg);
}

.body-right-4 {
  top: 70%;
  right: 20%;
}
.body-right-4::before {
  content: '';
  display: block;
  position: absolute;
  right: 104%;
  top: 50%;
  background-color: #969696;
  border-radius: 20rem;
  height: 3px;
  width: 180%;
}

.body-right-5 {
  top: 85%;
  right: 10%;
}
.body-right-5::before {
  content: '';
  display: block;
  position: absolute;
  right: 104%;
  top: 50%;
  background-color: #969696;
  border-radius: 20rem;
  height: 3px;
  width: 225%;
}
