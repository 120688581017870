.memory-game-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.memory-game-wrapper {
  box-sizing: content-box;
  width: 26.87em;
  padding: 2.5em 3em;
  background-color: #ffffff;
  border-radius: 0.6em;
  box-shadow: 0 0.9em 2.8em rgba(86, 66, 0, 0.2);
}

/* ------------------------------- Game ----------------------------- */
.memory-game-game-container {
  width: 100%;
  display: grid;
  gap: 0.6em;
  grid-template-columns: repeat(4, auto);
}

/* ------------------------------- Stats ----------------------------- */
.memory-game-stats-container {
  text-align: right;
  margin-bottom: 1.2em;
}
.memory-game-stats-container span {
  font-weight: 600;
}

/* ------------------------------- Card ------------------------------ */
.memory-game-card-container {
  position: relative;
  width: 6.25em;
  height: 6.25em;
  cursor: pointer;
}

.memory-game-card-container img {
  height: auto !important;
  width: auto !important;
}

.memory-game-card-before,
.memory-game-card-after {
  position: absolute;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #000000;
  transition: transform 0.5s ease-out;
  backface-visibility: hidden;
}
.memory-game-card-before {
  background-color: #f4c531;
  font-size: 2.8em;
  font-weight: 600;
}
.memory-game-card-after {
  background-color: #ffffff;
  transform: rotateY(180deg);
}
.memory-game-card-container.flipped .memory-game-card-before {
  transform: rotateY(180deg);
}
.memory-game-card-container.flipped .memory-game-card-after {
  transform: rotateY(0deg);
}
/* ---------------------------- Controls --------------------------------*/

.game-btn {
  width: 100%;
  border: none;
  border-radius: 0.3em;
  padding: 1em 1.5em;
  cursor: pointer;
  background-color: #000000;
  color: #ffffff;
  font-size: 1.1em;
  margin-top: 1.3em;
}

#result {
  text-align: center;
}
#result h2 {
  font-size: 2.5em;
}
#result h4 {
  font-size: 1.8em;
  margin: 0.6em 0 1em 0;
}
